// Dependencies
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 38, 0.4);
`;
