import React, { FunctionComponent } from 'react'
import { Container, Wrapper } from './bottom-sheet.styles'

export const BottomSheet: FunctionComponent<any> = ({
  content,
  isOpen,
  handleClick
}) => {
  return (
    <Container>
      <Wrapper collapsed={!isOpen}>
        <div className="buttom" onClick={handleClick}>
          <div className="line"></div>
        </div>

        <div className="content" >
          {content}
        </div>
      </Wrapper>
    </Container>
  )
}
