// Dependencies
import styled from 'styled-components'

export const Container = styled.form`
  min-width: 272px;

  > .gabarito-button {
    display: flex;
  }

  > .gabarito-modal-input {
    margin-bottom: 16px;
  }
`
