import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;

  @media (min-width: 1536px) {
    display: none;
  }

  > {
    margin: 0;
  }
`

export const Wrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  transition: all 300ms;

  > div.buttom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 12px;
    border-radius: 4px 4px 0px 0px;
    background: #571d5d;
    cursor: pointer;

    > div.line {
      width: 74px;
      height: 2px;
      border-radius: 50px;
      background: #ffffff;
    }
  }

  > div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 138px;
    background: #571d5d;

    > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  ${({ collapsed }) =>
    !!collapsed &&
    css`
      transition: all 300ms;
      bottom: -96px;
    `}
`
