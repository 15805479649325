// Dependencies
import styled, { css } from 'styled-components'

export type ContainerStyleProps = {
  size: number
  color: string
  hasCursorPointer: boolean
}

export const Container = styled.div<ContainerStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;

  ${({ size }) =>
    css`
      width: ${`${size}px`};
      height: ${`${size}px`};
    `}

  > svg {
    width: 100%;
    height: 100%;

    ${({ hasCursorPointer }) =>
      !!hasCursorPointer &&
      css`
        cursor: pointer;
      `}

    ${({ color }) =>
      css`
        fill: ${color};
        stroke: ${color};
      `}
  }
`
