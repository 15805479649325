// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M19.207 9.793c0.39 0.39 0.39 1.024 0 1.414l-11 11c-0.391 0.39-1.024 0.39-1.414 0l-5.5-5.5c-0.391-0.39-0.391-1.024-0-1.414s1.024-0.391 1.414-0l4.793 4.793 10.293-10.293c0.39-0.391 1.024-0.391 1.414 0z"></path>
    <path d="M30.707 9.793c0.39 0.39 0.39 1.024 0 1.414l-11 11c-0.39 0.39-1.024 0.39-1.414 0l-2.922-2.922c-0.391-0.39-0.391-1.024-0-1.414s1.024-0.39 1.414 0l2.215 2.214 10.293-10.293c0.39-0.391 1.024-0.391 1.414 0z"></path>
  </svg>
)
