import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 162px;
  background: #571d5d;
  border-radius: 0px 4px 0px 0px;

  ${MediaQueries.medium} {
    padding: 18px 54px;
    height: 283px;
  }

  ${MediaQueries.notebook} {
    height: 368px;
  }
`
