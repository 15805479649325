// Dependencies
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 8px;

  z-index: 4;

  background-color: #fff;

  > .gabarito-icon:first-child {
    position: absolute;
    top: -24px;
    right: 0px;
  }
`

export const FullContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  height: 100%;

  overflow-y: hidden;

  @media (min-width: 768px) {
    gap: 8px;
  }

  @media (min-width: 1280px) {
    overflow-y: unset;
    border-radius: 8px;
  }
`

export const TitleAndCloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`

export const TitleWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Title = styled.p``

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  @media (min-width: 1280px) {
    overflow-y: unset;
  }
`

export const FooterWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 32px 32px 8px 32px;
  display: flex;
  position: sticky;
  bottom: 0;

  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.15);

  @media (min-width: 1280px) {
    position: unset;
    bottom: unset;
    padding: 0;

    background-color: transparent;
    box-shadow: none;
  }
`
