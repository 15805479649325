import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  > div.content {
    display: flex;
    flex-direction: column;
    width: 320px;
    margin: 0 auto 48px;

    ${MediaQueries.medium} {
      width: 748px;
      padding: 0 40px;
    }

    ${MediaQueries.notebook} {
      width: 1008px;
      margin: 32px auto 48px;
      padding: 0;
    }
  }

  .gabarito-action > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 6px;
    flex-direction: row-reverse;

    ${MediaQueries.amedium} {
      padding: 24px 0px;
    }
  }

  .page-title > * {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-size: 24px;
    line-height: 44px;
    font-weight: 900;
    letter-spacing: -2px;
    text-transform: uppercase;
    color: #25272d;
    text-align: center;

    ${MediaQueries.medium} {
      font-size: 48px;
      line-height: 56px;
      text-align: start;
    }
  }

  .page-subtitle > * {
    margin: 0px;
    padding: 0px;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: #25272d;
    text-align: center;

    ${MediaQueries.medium} {
      font-size: 20px;
      line-height: 28px;
      text-align: start;
      margin-bottom: 32px;
    }
  }

  .questions-section > * {
    width: 100%;
    margin-bottom: 32px;

    display: flex;
    gap: 12px;
    align-items: center;
    align-content: center;
    flex: 1 0 0;
    flex-wrap: wrap;

    ${MediaQueries.medium} {
      gap: 12px 24px;
    }
  }

  .years-section  {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 12px;
    width: 100%;
  }

  .signout-user {
    display: block;
    max-width: 160px;
    margin: 8px auto 0px;

    ${MediaQueries.medium} {
      display: none;
    }
  }

  .gabarito-info > * {
    display: block;
    max-width: 180px;
    margin: 0px auto 24px;

    ${MediaQueries.medium} {
      display: none;
    }
  }

  .gabarito-card > * {
    display: none;

    @media (min-width: 1536px) {
      display: flex;
      position: absolute;
      top: 300px;
      right: calc(((100% - 1008px) / 2) - 240px);
    }
  }
`
