import styled from 'styled-components'

export const Container = styled.div`
  > div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 208px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.15);
    background: #571d5d;
  }

  .title,
  .description {
    margin: 0;
    font-style: normal;
  }

  .title {
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    text-transform: uppercase;
  }

  .description {
    margin: 8px 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #bca5be;
    text-align: center;
  }

  .button-container, .button {
    width: 100%;
  }
`
