import React, { FunctionComponent } from 'react'
import { BottomSheet } from 'component-library/bottom-sheet/bottom-sheet'
import { Container, TitleWrapper } from './bottom-sheet-gabarito.styles'

export const BottomSheetGabarito: FunctionComponent<any> = ({
  isOpen,
  titleComposition,
  onHandleClick,
  descriptionComposition,
  buttonComposition
}) => {
  return (
    <Container>
      <BottomSheet
        isOpen={isOpen}
        handleClick={onHandleClick}
        content={
          <div>
            <TitleWrapper titleWithAction={!isOpen}>
              <div className="title">{titleComposition}</div>
            </TitleWrapper>

            <div className="description">{descriptionComposition}</div>

            <div className="button-container">
              <div className="button">{buttonComposition}</div>
            </div>
          </div>
        }
      />
    </Container>
  )
}
