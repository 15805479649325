// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M16 9c0.552 0 1 0.448 1 1v7c0 0.552-0.448 1-1 1s-1-0.448-1-1v-7c0-0.552 0.448-1 1-1z"></path>
    <path d="M10.678 3.152c0.243-0.1 0.503-0.152 0.765-0.152h9.113c0.263 0 0.523 0.052 0.765 0.152s0.463 0.248 0.649 0.434l6.444 6.444c0.186 0.186 0.333 0.406 0.434 0.649s0.152 0.503 0.152 0.765v9.113c0 0.263-0.052 0.523-0.152 0.765s-0.248 0.463-0.434 0.649l-6.444 6.444c-0.186 0.186-0.406 0.333-0.649 0.434s-0.503 0.152-0.765 0.152h-9.113c-0.263 0-0.523-0.052-0.765-0.152s-0.463-0.248-0.649-0.434l-6.444-6.444c-0.186-0.186-0.333-0.406-0.434-0.649s-0.152-0.503-0.152-0.765v-9.113c0-0.263 0.052-0.523 0.152-0.765s0.248-0.463 0.434-0.649l6.444-6.444c0.186-0.186 0.406-0.333 0.649-0.434zM20.556 5h-9.113l-6.444 6.444v9.113l6.444 6.444h9.113l6.444-6.444v-9.113l-6.444-6.444z"></path>
    <path d="M16 23c0.828 0 1.5-0.672 1.5-1.5s-0.672-1.5-1.5-1.5c-0.828 0-1.5 0.672-1.5 1.5s0.672 1.5 1.5 1.5z"></path>
  </svg>
)
