// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { ConditionallyRenderProps } from './conditionally-render.types'

export const ConditionallyRender: FunctionComponent<
  ConditionallyRenderProps
> = ({ shouldRender, content }) => {
  if (!shouldRender) {
    return null
  }

  return <>{content}</>
}
