import { W, X } from '../mocks/IRTQuestionData';

/// The [Gauss-Hermite quadrature][0] sample points and weights below
/// were generated with the following Mathematica program:
///
/// ```
/// n := 256
/// Subscript[x, i_] := Root[HermiteH[n, #] &, i]
/// Subscript[w, i_] := (2^(n-1) n! Sqrt[Pi])/(n^2 HermiteH[n-1, Subscript[x, i]]^2)
///
/// X := Table[Subscript[x, i] Sqrt[2] // N[#, 17] &, {i, 1, n}]
/// W := Table[Subscript[w, i] // N[#, 17] &, {i, 1, n}]
/// ```
///
/// Weights are normalized so their sum equals 1. This makes it
/// easier to maximize usage of floating-point's dynamic range and
/// avoid overflow, at the expense of multiplying the approximation
/// by an unknown constant (which is not a problem for this
/// application since we only ever compute the _ratio_ between such
/// integrals).
///
/// [0]: https://en.wikipedia.org/wiki/Gauss%E2%80%93Hermite_quadrature

interface IIRTQuestionData {
  a: number;
  b: number;
  c: number;
}

const MEAN = 600;
const STDEV = 110;

class IRTExamParameters {
    questionCount: number;
    mean: number;
    stdev: number;
    questions: IIRTQuestionData[];
}

const initArray = (s1: number, s2: number, s3: number) =>
    Array.apply(null, { length: s1 }).map((_a: any) =>
        Array.apply(null, { length: s2 }).map((_b: any) => Array.apply(null, { length: s3 }))
    );

const calculateGrades = (examParameters: IRTExamParameters, answers: number[]): number => {
    const probability = computeResponseCurve(examParameters, answers);
    const K = X.map((_item, index) => index);

    let num = 0.0;
    let den = 0.0;

    K.forEach((k) => {
        num += probability[k] * W[k] * X[k];
        den += probability[k] * W[k];
    });

    // probability.reduce()

    const grade = num / den;

    return examParameters.mean + grade * examParameters.stdev;
};

const computeSigmoid = (a: number, b: number, c: number): number[] => {
    // tslint:disable-next-line:variable-name
    // const std_a = a / stdev;
    // tslint:disable-next-line:variable-name
    // const std_b = (b - mean) / stdev;

    const f = X.map((x) => {
        const z = a * (x - b);
        const sigmoid = 1.0 / (1.0 + Math.exp(-z));
        return sigmoid;
    });

    return f;
};

// Calcula a curva de resposta para cada questao
const computeResponseCurve = (examParameters: IRTExamParameters, answers: number[]) => {
    // Esta variável armazena uma matriz com a probabilidade: m[i][j] do estudante com proficiencia X ter o resultado j na iésima questão.

    const m: number[][][] = initArray(examParameters.questionCount, 2, X.length);
    const indexX = X.map((_item, index) => index);

    examParameters.questions.forEach((question, indexQuestao) => {
        const { a, b, c } = question;
        const f = computeSigmoid(a, b, c);

        indexX.forEach((k) => {
            // calcula a probabilidade nos dois estados: erro e acerto
            const P = c + (1.0 - c) * f[k]; // chance de acertar

            m[indexQuestao][0][k] = 1 - P; // chance de errar
            m[indexQuestao][1][k] = P;
        });
    });

    const probability: number[] = new Array<number>(X.length);
    probability.fill(Number.MAX_VALUE);
    examParameters.questions.forEach((_question, i) => {
        indexX.forEach((k) => {
            probability[k] *= m[i][answers[i]][k];
        });
    });

    return probability;
};

const CalculateGradesENEM = (answers: number[], IRTQuestionData: IIRTQuestionData[], _subjectId: number): number => {
    const examParameters = new IRTExamParameters();
    if (answers.length !== IRTQuestionData.length) {
        throw new Error('invalid number of questions or IRT parameters');
    }

    examParameters.questionCount = IRTQuestionData.length;
    examParameters.mean = MEAN;
    examParameters.stdev = STDEV;

    examParameters.questions = IRTQuestionData;

    return calculateGrades(examParameters, answers);
};

export default CalculateGradesENEM;
