import React, { FunctionComponent, useEffect } from 'react'
import { Container } from './picture-in-picture.styles'

export const PictureInPicture: FunctionComponent = ({ children }) => {
  useEffect(() => {
    const iframes = document.querySelectorAll('iframe')

    iframes.forEach((iframe: HTMLElement) => {
      excuteQuery('767', '320', iframe, {
        width: '320px',
        height: '162px'
      })

      excuteQuery('1365', '768', iframe, {
        'max-width': '510px',
        'max-height': '247px',
        width: '100%',
        height: '100%'
      })

      excuteQuery('5000', '1366', iframe, {
        'max-width': '588px',
        'max-height': '332px',
        width: '100%',
        height: '100%'
      })
    })

    document.addEventListener('scroll', function () {
      const content = document.querySelector(
        '.gabarito-picture-in-picture'
      ) as HTMLElement

      if (!content) return

      if (
        (window.scrollY > 432 && window.innerWidth < 768) ||
        (window.scrollY > 542 &&
          window.innerWidth >= 768 &&
          window.innerWidth < 1366) ||
        (window.scrollY > 660 && window.innerWidth >= 1366)
      ) {
        let leftValue = 24

        if (window.innerWidth < 768) {
          if (window.innerWidth < 360) {
            leftValue = (window.innerWidth - 320) / 2
          } else {
            leftValue = 20
          }
        }

        content.style.width = '320px'
        content.style.height = '162px'
        content.style.position = 'fixed'
        content.style.bottom = `${window.innerWidth < 1536 ? 68 : 24}px`
        content.style.left = `${leftValue}px`
        content.style.zIndex = '2'
        content.style.flexWrap = 'wrap'
      } else {
        content.style.width = '100%'
        content.style.height = '100%'
        content.style.position = 'initial'
        content.style.bottom = 'auto'
        content.style.left = 'auto'
        content.style.zIndex = '1'
        content.style.backgroundColor = 'transparent'
      }
    })
  }, [])

  const excuteQuery = (
    mediaMax: string,
    mediaMin: string,
    iframe: HTMLElement,
    cssProps: any
  ) => {
    const queryMin: MediaQueryList = matchMedia(`(min-width: ${mediaMin}px)`)

    const queryMax: MediaQueryList = matchMedia(`(max-width: ${mediaMax}px)`)

    const executeQuery = (exp: MediaQueryListEvent | MediaQueryList) => {
      if (exp.matches) {
        Object.keys(cssProps).map((cssProp) => {
          iframe.style[cssProp as any] = cssProps[cssProp]
        })

        iframe.style.display = 'block'
        iframe.style.margin = '0px auto'
      }
    }

    executeQuery(queryMin)

    queryMin.addEventListener('change', executeQuery)
    queryMax.addEventListener('change', executeQuery)
  }

  return (
    <Container className="gabarito-picture-in-picture">{children}</Container>
  )
}
