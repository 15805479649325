import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  > label {
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: inherit;
  }
`

type InputElementStyleProps = {
  status: string
  type: string
}

export const InputElement = styled.input<InputElementStyleProps>`
  height: 48px;
  width: 100%;

  font-family: 'Fira Sans';
  font-size: 1.125rem;
  line-height: 1.27;
  color: #25272d;

  padding: 0.66em 1.11em;

  border-radius: inherit;
  border: 1px solid #9c9fb0;
  outline: 0;
  background-repeat: no-repeat;
  background-position: right 1.11em center;

  &::placeholder {
    color: #ced0dd;
  }

  &:hover {
    border: 1px solid #505462;
  }

  &:focus {
    border: 1px solid #505462;
    box-shadow: 0px 0px 0px 2px #94d2ff;
  }

  &:disabled {
    box-shadow: 0px 0px 0px 2px #94d2ff;
    border: 1px solid #ced0dd;
    background: #f0f1f7;
  }

  ${({ status }) =>
    status === 'positive' &&
    css`
      color: #1d9a6c;
      border-color: 1px solid #1d9a6c;
    `}

  ${({ status }) =>
    status === 'negative' &&
    css`
      color: #ad4012;
      border-color: 1px solid #ad4012;
    `}

    ${({ type }) =>
    type === 'number' &&
    css`
      -moz-appearance: textfield;
      appearance: textfield;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}
`

type FeedbackWrapperStyleProps = {
  status: string
}

export const FeedbackWrapper = styled.div<FeedbackWrapperStyleProps>`
  width: fit-content;
  min-height: 24px;
  max-width: 200px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  gap: 10px;
  align-items: center;

  transform: translateY(-25%);
  position: absolute;
  right: 0;

  @media (min-width: 768px) {
    font-weight: 400;
  }

  @media (min-width: 1366px) {
    font-weight: 400;
  }

  ${({ status }) =>
    status === 'positive' &&
    css`
      background-color: #deedcf;
      color: #1d9a6c;
    `}

  ${({ status }) =>
    status === 'negative' &&
    css`
      background-color: #ffd396;
      color: #c05214;
    `}
`

