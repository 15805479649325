import React, { FunctionComponent, useEffect } from 'react'
import { Container } from './tabs.styles'

export const Tabs: FunctionComponent<any> = ({
  value,
  action,
  tabComposition,
  tabMoreComposition,
  tabPanelComposition
}) => {
  const toggleTab = (): void => {
    const elTabsSlot = document.querySelector(
      '.gabarito-tabs-container > slot:last-child'
    )

    const assignedElementsSlot = elTabsSlot?.childNodes

    const filteredTabs: any = []

    assignedElementsSlot?.forEach((el: any) => {
      if (el.tagName.toLocaleLowerCase() == 'gabarito-tab') {
        filteredTabs.push(el)
      } else {
        const subElsFiltered = el.querySelectorAll('gabarito-tab')

        subElsFiltered?.forEach((subEls: any) => filteredTabs.push(subEls))
      }
    }) as any

    if (value > filteredTabs.length - 1 || value < filteredTabs.length - 1) {
      filteredTabs.forEach((tab: any) =>
        modifyTabByIndex(filteredTabs.length - 1, tab)
      )
    }

    filteredTabs.forEach((tab: any) => modifyTabByIndex(value, tab))
  }

  const modifyTabByIndex = (value: number, tab: any): void => {
    if (tab.index == value) {
      tab.style.display = 'block'
    } else {
      tab.style.display = 'none'
    }
  }

  useEffect(() => {
    toggleTab()
  }, [])

  return (
    <Container>
      <div className="gabarito-tabs-container">
        <div className="gabarito-tab-panels">
          <div className="tab-panels">{tabPanelComposition}</div>

          <div className="tab-more">{tabMoreComposition}</div>
        </div>

        <div className="tabs">{tabComposition}</div>
      </div>
    </Container>
  )
}
