// Dependencies
import axios from 'axios'

type ContactData = {
  name: string
  email: string
  phone: string
}

export async function saveContact(contact: ContactData) {
  try {
    const resp = await axios.post(
      'https://simuladordetran.com.br/nitro/contact/add',
      {
        nome: contact.name,
        email: contact.email,
        telefone: contact.phone,
        lista: '-VmH'
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    return resp.status === 200
  } catch (error) {
    return null
  }
}
