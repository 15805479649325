// Dependencies
import React, { FunctionComponent } from 'react'
import { Container } from './info-user-gabarito.styles'

export const InfoUserGabarito: FunctionComponent<any> = ({
  showHeader,
  titleComposition,
  buttonComposition,
  descriptionComposition,
  handleButtonClick
}) => {
  return (
    <Container>
      <div className="gabarito-info-user-container">
        <div>
          {!!showHeader && (
            <>
              <div className="title">{titleComposition}</div>

              <div
                className="button"
                onClick={() => !!handleButtonClick && handleButtonClick()}
              >
                {buttonComposition}
              </div>
            </>
          )}
        </div>

        <div className="description">{descriptionComposition}</div>
      </div>
    </Container>
  )
}
