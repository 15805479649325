// Dependencies
import firebase from 'firebase'

export class FirebaseService {
  private static config = {
    // apiKey: 'AIzaSyCxc3QVseZvKek6FqYfMLGwVhmV2HrBPsU',
    authDomain: 'prosisu-e43eb.firebaseapp.com ',
    databaseURL: `${process.env.FIREBASE_GABARITO_URL}`,
    storageBucket: 'bucket.appspot.com'
  }

  private static database?: firebase.database.Database = undefined

  private static get db(): firebase.database.Database {
    if (this.database === undefined) {
      throw new Error('O banco não foi inicializado')
    }

    return this.database
  }

  public static init(config?: any) {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(config ?? this.config)
    }

    this.database = firebase.database()
  }

  public static get = async (url: string): Promise<any> => {
    const response = await this.db.ref(url).once('value')

    return response.val()
  }

  public static set = (url: string, data: any) => {
    this.db.ref(url).set(data)
  }

  public static update = (url: string, data: any) => {
    this.db.ref(url).update(data)
  }

  public static onChange = (
    url: string,
    onChange: (value: any | null) => void
  ) => {
    this.db.ref(url).on('value', (snapshot: any | null) => {
      if (snapshot) {
        onChange(snapshot.val())
        return
      }

      onChange(null)
    })
  }
}
