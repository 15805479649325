import React, { FunctionComponent, useMemo } from 'react'
import { Container, Progress } from './progress-bar.styles'

export const ProgressBar: FunctionComponent<any> = ({ progress }) => {
  const formattedProgress = useMemo(() => {
    if (progress > 100) {
      return 100
    }

    if (progress < 0) {
      return 0
    }

    return progress
  }, [])

  return (
    <Container className='gabarito-progress-bar'>
      <div className="bar-container">
        <Progress progress={formattedProgress} />
      </div>
    </Container>
  )
}
