const USER_LOCAL_STORAGE = '@proenem-user:lead'

const hasLocalStorage = () => {
  return typeof localStorage !== 'undefined'
}

export const setUserLocalStorage = (payload: any) => {
  if (!hasLocalStorage()) {
    return false
  }

  localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify({ user: payload }))

  return true
}

export const getUserLocalStorage = () => {
  if (!hasLocalStorage()) {
    return null
  }

  const data = localStorage.getItem(USER_LOCAL_STORAGE)

  if (!data) return null

  return JSON.parse(data)
}

export const clearUserLocalStorage = () => {
  localStorage?.removeItem(USER_LOCAL_STORAGE)
}
