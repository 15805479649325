import styled, { css } from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  width: 100%;
  background: #df1d3d;

  margin: 0px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${MediaQueries.notebook} {
    padding: 24px 24px 32px;

    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .section-results {
    width: 100%;
    padding: 16px 24px;
    background: #c11636;

    ${MediaQueries.notebook} {
      padding: 0;
      background: transparent;
    }
  }

  .select-section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    padding: 16px 24px;

    ${MediaQueries.notebook} {
      width: 360px;
      margin-right: 40px;
      padding: 0;
    }

  }

  .select-section-title {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 0px 8px;
  }

  .select-section-lang,
  .select-section-color {
    display: block;

    width: 100%;
  }

  .select-section-title,
  .section-results-title {
    display: block;

    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: -0.015em;
    line-height: 16px;
    color: #fdf4f5;

    margin-bottom: 12px;
    text-align: center;

    ${MediaQueries.medium} {
      text-align: start;
    }
  }
`

export const SelectSessionDetailWrapper = styled.div<{
  shouldDisplaySelectSectionLangComposition: boolean
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  ${MediaQueries.notebook} {
    ${({ shouldDisplaySelectSectionLangComposition }) =>
      !!shouldDisplaySelectSectionLangComposition &&
      css`
        .select-section-lang,
        .select-section-color {
          max-width: 180px;
        }
      `}

    ${({ shouldDisplaySelectSectionLangComposition }) =>
      !shouldDisplaySelectSectionLangComposition &&
      css`
        .select-section-lang,
        .select-section-color {
          max-width: 360px;
        }
      `}
  }
`
