import styled from 'styled-components'

export const Container = styled.div`
  max-width: 497px;
  height: auto;
  display: block;
  padding: 20px;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 8px;
  margin: auto;
`
