import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  .day-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 8px 0px;

    border-radius: 0px 0px 4px 4px;

    ${MediaQueries.medium} {
      grid-gap: 0px 44px;

      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .gabarito-progress-bar {
      width: 100%;

      margin-top: 16px;
      margin: 4px 0px;

      display: block;

      ${MediaQueries.medium} {
        margin: 8px 0px;
      }

      ${MediaQueries.notebook} {
        margin: 16px 0px 8px;
      }
    }

    .day-column-results,
    .day-total-results {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > div {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          line-height: 16px;
        }

        span:first-child {
          font-size: 14px;
        }

        span:last-child {
          font-weight: bold;
          font-size: 18px;
        }
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: #fdf4f5;
      }
    }
  }
`
