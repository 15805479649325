import React, { FunctionComponent } from 'react'
import { Container } from './tab.styles'

export const Tab: FunctionComponent<any> = ({ content }) => {
  return (
    <Container>
      {content}
    </Container>
  )
}
