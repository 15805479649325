// Dependencies
import React from 'react'

// Utils
import { data } from './gabaritos.data'
import { EditionDaysUnion } from './gabaritos.model'
import { useGabaritos } from './gabaritos.hook'
import { getQuestionNumber } from './gabaritos.helpers'

// Componentes
import Layout from '../../components/Layout'
import ToggleGabaritos from '../../components/ToggleUseComponent'
import { Button } from 'component-library/button'
import { GabaritoWorkspace } from 'component-library/gabarito/gabarito'
import { SisuCardGabarito } from 'component-library/sisu-card-gabarito/sisu-card-gabarito'
import { Icon } from 'component-library/icon'
import { InfoUserGabarito } from 'component-library/info-user-gabarito/info-user-gabarito'
import { BottomSheetGabarito } from 'component-library/bottom-sheet-gabarito/bottom-sheet-gabarito'
import { DayResults } from 'component-library/day-results/day-results'
import { Select } from 'component-library/select/select'
import { Label } from 'component-library/label/label'
import { PictureInPicture } from 'component-library/picture-in-picture/picture-in-picture'
import { Tabs } from 'component-library/tabs/tabs'
import { GabaritoResults } from 'component-library/gabarito-results/gabarito-results'
import { Question } from 'component-library/question/question'
import { Tab } from 'component-library/tab/tab'
import { TabPanel } from 'component-library/tab-panel/tab-panel'
import { ScreenWidthRender } from 'components/utilities/screen-width-render'
import ModalLead from 'components/ModalLead'

export const AlternativeOptions = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E'
} as const

export type AlternativeOption =
  (typeof AlternativeOptions)[keyof typeof AlternativeOptions]

export const Gabaritos = () => {
  const {
    filters,
    selectedEnemEdition,
    selectedEnemEditionData,
    userData,
    handleSignOut,
    getEditionLabel,
    modalContent,
    selectedEditionDayIndex,
    handleSelectTab,
    dayResults,
    colorOptions,
    selectSelectedColor,
    handleSelectColor,
    selectedEditionDayLabel,
    selectSelectedLanguage,
    handleSelectLanguage,
    editionDayResponses,
    selectedLanguage,
    selectedColor,
    editionDayUserResponses,
    editionDayResponsesStart,
    handleSelectQuestion,
    handleSelectEnemEdition,
    handleCopyToClipboard,
    hasUnansweredEditionDayQuestions,
    handleOpeningSisuSite,
    bottomSheetIsOpen,
    setBottomSheetIsOpen,
    hasVisibleCorrection,
    handleSubmittedFormData,
    setHasVisibleCorrection,
    setModalContent
  } = useGabaritos()

  if (!filters || !selectedEnemEdition || !selectedEnemEditionData) {
    return null
  }

  return (
    <Layout disableFixed={true} screen="gabaritos">
      <ModalLead
        handleClose={() => {
          setModalContent(null)
        }}
        isOpened={!!modalContent}
        onFailure={(message) => console.log(message)}
        formData={modalContent}
        onSuccess={handleSubmittedFormData}
      />

      <GabaritoWorkspace
        sisuCardComposition={
          <SisuCardGabarito
            titleComposition={<div slot="title">{data.sisuCard.title}</div>}
            descriptionComposition={
              <div slot="description">{data.sisuCard.description}</div>
            }
            buttonComposition={
              <Button
                variant="primary"
                size='small'
                isFullyAdaptative
                label={data.sisuCard.buttonLabel}
                onClick={handleOpeningSisuSite}
              />
            }
          />
        }
        shouldDisplaySignoutUserComposition={!!userData}
        signoutUserComposition={
          <InfoUserGabarito
            showHeader
            titleComposition={<div slot="title">Olá, {userData?.name}!</div>}
            handleButtonClick={handleSignOut}
            buttonComposition={
              <>
                <Icon name={'signOut'} color="#df1d3d" size={12} />
                Sair
              </>
            }
          />
        }
        pageTitleComposition={<h1>gabarito enem {getEditionLabel}</h1>}
        pageSubtitleComposition={
          <h4 slot="page-subtitle">
            Confira o seu desempenho na prova e simule a sua nota do ENEM 2023
            com TRI
          </h4>
        }
        gabaritoInfoComposition={
          <InfoUserGabarito
            slot="gabarito-info"
            descriptionComposition={
              <>
                <Icon name={'warningOctagon'} color="#9c9fb0" />
                Este Gabarito é extraoficial.
              </>
            }
          />
        }
        gabaritoTabsComposition={
          <Tabs
            slot="gabarito-tabs"
            value={selectedEditionDayIndex}
            action
            tabComposition={
              <div slot="tabs">
                {selectedEnemEditionData.map(
                  (editionDayData, index) =>
                    selectedEditionDayIndex === index && (
                      <Tab
                        key={`tab-${index}`}
                        content={
                          selectedEditionDayIndex === index && (
                            <PictureInPicture>
                              <iframe
                                src={editionDayData.videoUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </PictureInPicture>
                          )
                        }
                      />
                    )
                )}
              </div>
            }
            tabMoreComposition={
              <InfoUserGabarito
                showHeader={!!userData}
                titleComposition={
                  <div slot="title">Olá, {userData?.name}!</div>
                }
                handleButtonClick={handleSignOut}
                buttonComposition={
                  <>
                    <Icon name={'signOut'} color="#df1d3d" size={12} />
                    Sair
                  </>
                }
                descriptionComposition={
                  <>
                    <Icon name={'warningOctagon'} color="#9c9fb0" />
                    Atenção! Este Gabarito é extraoficial.
                  </>
                }
              />
            }
            tabPanelComposition={filters.days.map((editionDay, index) => (
              <TabPanel
                key={editionDay.key}
                index={index}
                content={selectedEnemEditionData[index].name}
                value={selectedEditionDayIndex}
                onClick={() => {
                  handleSelectTab(editionDay.key as EditionDaysUnion, index)
                }}
              />
            ))}
          />
        }
        gabaritoResultsComposition={
          <GabaritoResults
            selectSectionTitleComposition={
              <span slot="select-section-title">
                SELECIONE DE ACORDO COM A SUA PROVA
              </span>
            }
            selectSectionColorComposition={
              <Select
                label="Cor da Prova"
                options={colorOptions}
                selected={selectSelectedColor}
                onChange={handleSelectColor}
              />
            }
            shouldDisplaySelectSectionLangComposition={
              selectedEditionDayLabel === 'dia-1'
            }
            selectSectionLangComposition={
              <Select
                label="Idioma da Prova"
                options={data.testLanguages}
                selected={selectSelectedLanguage}
                onChange={handleSelectLanguage}
              />
            }
            shouldDisplaySectionResultsTitleComposition={
              !!dayResults && !!userData
            }
            sectionResultsTitleComposition={
              <span slot="section-results-title">ACERTOS</span>
            }
            shouldDisplaySectionResultsComposition={!!dayResults && !!userData}
            sectionResultsComposition={
              <DayResults
                slot="section-results"
                subjectResults={dayResults?.subjectResults}
                totalResults={dayResults?.totalResults}
              />
            }
          />
        }
        questionsSectionComposition={
          <div slot="questions-section">
            {editionDayUserResponses.split('').map((question, index) => {
              return (
                <Question
                  key={`question-${editionDayResponsesStart + index}`}
                  questionNumber={getQuestionNumber(
                    editionDayResponsesStart + index
                  )}
                  correctAlternative={
                    !!editionDayResponses &&
                    (!!selectedLanguage ||
                      selectedEditionDayLabel === 'dia-2') &&
                    !!selectedColor &&
                    data.alternatives.indexOf(editionDayResponses[index]) > -1
                      ? (editionDayResponses[index] as AlternativeOption)
                      : undefined
                  }
                  selectedAlternative={
                    question === '?'
                      ? undefined
                      : (question as AlternativeOption)
                  }
                  isCanceled={
                    !!editionDayResponses && editionDayResponses[index] === 'X'
                  }
                  hasCorrectionAvailable={
                    !!editionDayResponses &&
                    !!selectedColor &&
                    (!!selectedLanguage || selectedEditionDayLabel === 'dia-2')
                      ? editionDayResponses[index] !== '?'
                      : undefined
                  }
                  hasVisibleCorrection={
                    !!editionDayResponses && editionDayResponses[index] !== '?'
                      ? hasVisibleCorrection
                      : false
                  }
                  onQuestionClick={(event: CustomEvent) => {
                    handleSelectQuestion(event, index)
                  }}
                />
              )
            })}
          </div>
        }
        yearsSectionComposition={filters.years.map((year) => (
          <Label
            key={year.key}
            label={year.value}
            slug={year.key}
            isSelected={year.key === selectedEnemEdition}
            onLabelClick={handleSelectEnemEdition}
          />
        ))}
        sisuBottomSheetComposition={
          <BottomSheetGabarito
            isOpen={bottomSheetIsOpen}
            onHandleClick={() => {
              setBottomSheetIsOpen(!bottomSheetIsOpen)
            }}
            titleComposition={<div slot="title">{data.sisuCard.title}</div>}
            descriptionComposition={
              <div slot="description">{data.sisuCard.description}</div>
            }
            buttonComposition={
              <>
                <ScreenWidthRender
                  actionAfterRenderingWidth="hide"
                  renderingWidth={1536}
                  content={
                    <Button
                      variant="primary"
                      isFullyAdaptative
                      size="small"
                      label={data.sisuCard.buttonLabel}
                      onClick={handleOpeningSisuSite}
                    />
                  }
                />

                <ScreenWidthRender
                  actionAfterRenderingWidth="show"
                  renderingWidth={1536}
                  content={
                    <Button
                      variant="primary"
                      label={data.sisuCard.buttonLabel}
                      onClick={handleOpeningSisuSite}
                    />
                  }
                />
              </>
            }
          />
        }
        gabaritoActionComposition={
          <div slot="gabarito-action">
            {!!editionDayResponses &&
              !!userData &&
              (!!selectedLanguage || selectedEditionDayLabel === 'dia-2') &&
              !!selectedColor && (
                <>
                  <ToggleGabaritos
                    onToggleSwitchClicked={() =>
                      setHasVisibleCorrection(!hasVisibleCorrection)
                    }
                    activeToggle={hasVisibleCorrection}
                  />
                  {!hasUnansweredEditionDayQuestions && (
                    <Label
                      label={'Copie o Gabarito'}
                      isSelected={false}
                      icon="copy"
                      onLabelClick={handleCopyToClipboard}
                    />
                  )}
                </>
              )}
          </div>
        }
      />
    </Layout>
  )
}
