// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M21.044 10.043c0.391-0.39 1.024-0.39 1.414 0l5.249 5.25c0.39 0.39 0.39 1.023 0 1.414l-5.249 5.25c-0.39 0.391-1.024 0.391-1.414 0s-0.391-1.024-0-1.414l4.542-4.543-4.542-4.543c-0.39-0.391-0.39-1.024 0-1.414z"></path>
    <path d="M12 16c0-0.552 0.448-1 1-1h13.996c0.552 0 1 0.448 1 1s-0.448 1-1 1h-13.996c-0.552 0-1-0.448-1-1z"></path>
    <path d="M4.586 4.586c0.375-0.375 0.884-0.586 1.414-0.586h7c0.552 0 1 0.448 1 1s-0.448 1-1 1h-7v20h7c0.552 0 1 0.448 1 1s-0.448 1-1 1h-7c-0.53 0-1.039-0.211-1.414-0.586s-0.586-0.884-0.586-1.414v-20c0-0.53 0.211-1.039 0.586-1.414z"></path>
  </svg>
)
