// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M4.72 10.97c2.35-2.35 6.031-4.72 11.28-4.72s8.93 2.369 11.28 4.72c1.173 1.173 2.019 2.345 2.573 3.224 0.277 0.441 0.483 0.809 0.62 1.071 0.069 0.131 0.12 0.235 0.155 0.308 0.018 0.037 0.031 0.065 0.041 0.086l0.011 0.024 0.003 0.007 0.001 0.002 0 0.001c0 0 0 0.001-0.685 0.305 0.685 0.305 0.685 0.305 0.685 0.305l-0 0.001-0.001 0.002-0.003 0.007-0.011 0.024c-0.009 0.020-0.023 0.049-0.041 0.086-0.035 0.073-0.087 0.177-0.155 0.308-0.137 0.262-0.342 0.631-0.62 1.071-0.554 0.88-1.4 2.051-2.573 3.224-2.35 2.35-6.031 4.72-11.28 4.72s-8.93-2.369-11.28-4.72c-1.173-1.173-2.019-2.345-2.573-3.224-0.277-0.441-0.482-0.809-0.62-1.071-0.069-0.131-0.12-0.235-0.155-0.308-0.018-0.037-0.031-0.065-0.041-0.086l-0.011-0.024-0.003-0.007-0.001-0.002-0-0.001c-0-0-0-0.001 0.685-0.305-0.685-0.305-0.685-0.305-0.685-0.305l0-0.001 0.001-0.002 0.003-0.007 0.011-0.024c0.009-0.021 0.023-0.049 0.041-0.086 0.035-0.073 0.087-0.177 0.155-0.308 0.137-0.262 0.342-0.631 0.62-1.071 0.554-0.88 1.4-2.051 2.573-3.224zM2 16l-0.685-0.305c-0.086 0.194-0.086 0.417 0 0.611l0.685-0.305zM2.836 16c0.007 0.013 0.013 0.026 0.020 0.039 0.121 0.23 0.306 0.565 0.56 0.968 0.509 0.808 1.287 1.886 2.364 2.963 2.15 2.15 5.469 4.28 10.22 4.28s8.070-2.131 10.22-4.28c1.077-1.077 1.856-2.155 2.364-2.963 0.254-0.403 0.439-0.737 0.56-0.968 0.007-0.013 0.014-0.026 0.020-0.039-0.006-0.013-0.013-0.025-0.020-0.039-0.121-0.23-0.306-0.565-0.56-0.968-0.509-0.808-1.287-1.886-2.364-2.963-2.15-2.15-5.469-4.28-10.22-4.28s-8.070 2.131-10.22 4.28c-1.077 1.077-1.856 2.155-2.364 2.963-0.254 0.403-0.439 0.737-0.56 0.968-0.007 0.013-0.014 0.026-0.020 0.039zM30 16l0.685 0.305c0.086-0.194 0.086-0.417 0-0.611l-0.685 0.305zM29.315 16.305c0 0 0 0 0 0z"></path>
    <path d="M16 11.75c-2.347 0-4.25 1.903-4.25 4.25s1.903 4.25 4.25 4.25c2.347 0 4.25-1.903 4.25-4.25s-1.903-4.25-4.25-4.25zM10.25 16c0-3.176 2.574-5.75 5.75-5.75s5.75 2.574 5.75 5.75-2.574 5.75-5.75 5.75c-3.176 0-5.75-2.574-5.75-5.75z"></path>
  </svg>
)
