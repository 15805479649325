import { ProgressBar } from 'component-library/progress-bar/progress-bar'
import React, { FunctionComponent } from 'react'
import { Container } from './day-results.styles'

export const DayResults: FunctionComponent<any> = ({
  subjectResults,
  totalResults
}) => {
  return (
    <Container>
      <div className="day-container">
        {subjectResults.map((subject: any, index: number) => (
          <div className="day-column-results" key={index}>
            <div>
              <span>{subject.label}</span>

              <span>
                {subject.result.hits} / {subject.result.total}
              </span>
            </div>

            <ProgressBar
              progress={(subject.result.hits / subject.result.total) * 100}
            />

            {!!subject.tri && (
              <span>
                TRI Estimada: {subject.tri.min} ~ {subject.tri.max}
              </span>
            )}
          </div>
        ))}

        <div className="day-total-results">
          <div>
            <span>Total - {totalResults.dayLabel}</span>

            <span>
              {totalResults.totalHits} / {totalResults.totalQuestions}
            </span>
          </div>

          <ProgressBar
            progress={
              (totalResults.totalHits / totalResults.totalQuestions) * 100
            }
          />
        </div>
      </div>
    </Container>
  )
}
