import styled, { css } from 'styled-components'

export const ButtonWrapper = styled.button<{
  variant: 'primary' | 'dark'
  isFullyAdaptative?: boolean
  size: 'large' | 'small'
}>`
  border-radius: 8px;
  padding: 8px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  transition: all ease 0.2s;

  cursor: pointer;

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 32px;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      height: 48px;
    `}

  ${({ isFullyAdaptative }) =>
    isFullyAdaptative &&
    css`
      width: 100%;
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background: #df1d3d;
      border: 1px solid #df1d3d;
      color: #fff;
    `}

    ${({ variant }) =>
    variant === 'dark' &&
    css`
      background: #25272d;
      border: 1px solid #25272d;
      color: #fff;
    `}
`

export const Label = styled.p`
  font-family: 'Fira Sans';
  font-style: normal;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
`
