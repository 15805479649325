// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M4 16c0-0.552 0.448-1 1-1h22c0.552 0 1 0.448 1 1s-0.448 1-1 1h-22c-0.552 0-1-0.448-1-1zM14.707 6.293c0.39 0.391 0.39 1.024 0 1.414l-8.293 8.293 8.293 8.293c0.39 0.39 0.39 1.024 0 1.414s-1.024 0.39-1.414 0l-9-9c-0.391-0.39-0.391-1.024 0-1.414l9-9c0.39-0.391 1.024-0.391 1.414 0z"></path>
  </svg>
)
