import React, { FunctionComponent, useEffect, useState } from 'react'
import { Container } from './gabarito.styles'

export const GabaritoWorkspace: FunctionComponent<any> = ({
  sisuCardComposition,
  signoutUserComposition,
  pageTitleComposition,
  pageSubtitleComposition,
  gabaritoInfoComposition,
  gabaritoTabsComposition,
  gabaritoResultsComposition,
  questionsSectionComposition,
  yearsSectionComposition,
  sisuBottomSheetComposition,
  gabaritoActionComposition,
  shouldDisplaySignoutUserComposition
}) => {
  return (
    <Container>
      <div className="gabarito-card">{sisuCardComposition}</div>

      <div className="content">
        {!!shouldDisplaySignoutUserComposition && (
          <div className="signout-user">{signoutUserComposition}</div>
        )}

        <div className="page-title">{pageTitleComposition}</div>

        <div className="page-subtitle">{pageSubtitleComposition}</div>

        <div className="gabarito-info">{gabaritoInfoComposition}</div>

        <div className="gabarito-tabs">{gabaritoTabsComposition}</div>

        <div className="gabarito-results">{gabaritoResultsComposition}</div>

        <div className="gabarito-action">{gabaritoActionComposition}</div>

        <div className="questions-section">{questionsSectionComposition}</div>

        <div className="years-section">{yearsSectionComposition}</div>

        <div className="gabarito-bottom-sheet">
          {sisuBottomSheetComposition}
        </div>
      </div>
    </Container>
  )
}
