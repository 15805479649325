import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ open: boolean }>`
  position: relative;

  > div.select-header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 16px;

    background: #e7536b;
    border-radius: 4px;
    transition: all 0.3s;

    cursor: pointer;

    ${({ open }) =>
      !!open &&
      css`
        background: #c11636;
      `}

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #fff;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }

  > div.select-body {
    width: 100%;

    display: flex;
    flex-direction: column;

    border: 1px solid #ee8899;
    background: #c11636;
    pointer-events: none;
    transition: all 0.15s;
    position: absolute;
    z-index: 2;
    border-radius: 4px;
    opacity: 0;
    overflow: hidden;
    bottom: -5px;

    ${({ open }) =>
      !!open &&
      css`
        pointer-events: all;
        transition: all 0.3s;
        opacity: 1;
      `}

    @media (min-width: 768px) {
      width: 390px;
    }

    > div.select-option {
      width: 100%;

      padding: 12px 16px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      transition: all 0.15s;

      span {
        margin-left: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #fff;

        @media (min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    > div.select-option:hover {
      background: #a10f2f;
      transition: all 0.3s;
    }
  }
`
