import React, { memo } from 'react'

//styles
import { Wrapper, NotShow, Show } from './styles'

//components
import { ToggleSwitch } from 'component-library/toggle-switch/toggle-switch'

interface IProps {
  activeColor?: string
  onToggleSwitchClicked: () => void
  activeToggle?: boolean
}

const ToggleGabaritos = ({
  activeColor = '#DF1D3D',
  activeToggle,
  onToggleSwitchClicked,
}: IProps) => {
  return (
    <Wrapper>
      <NotShow>Não exibir</NotShow>

      <ToggleSwitch
        activeColor={activeColor}
        handleClick={onToggleSwitchClicked}
        isActive={activeToggle}
      />
      <Show>Exibir</Show>
    </Wrapper>
  )
}

export default memo(ToggleGabaritos)
