// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M10 4.999c0-0.552 0.448-1 1-1h16c0.552 0 1 0.448 1 1v16c0 0.552-0.448 1-1 1h-6c-0.552 0-1-0.448-1-1s0.448-1 1-1h5v-14h-14v5c0 0.552-0.448 1-1 1s-1-0.448-1-1v-6z"></path>
    <path d="M4 10.999c0-0.552 0.448-1 1-1h16c0.552 0 1 0.448 1 1v16c0 0.552-0.448 1-1 1h-16c-0.552 0-1-0.448-1-1v-16zM6 11.999v14h14v-14h-14z"></path>
  </svg>
)
