import styled from 'styled-components'

export const Container = styled.div`
  > div.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 320px;
    padding: 12px 16px;
    background: #f0f1f7;

    > div.question-number {
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 44px;
      color: #571d5d;
    }

    > div.correct-question-number {
      color: #99d492;
    }

    > div.wrong-question-number {
      color: #e7536b;
    }

    > div.question-number-awaiting-correction {
      color: #ddd2df;
    }

    > div.alternatives {
      display: flex;
      flex-direction: row;
      align-items: center;

      > div.alternative {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: #ffffff;
        font-style: normal;
        font-weight: 900;
        font-size: 14.9211px;
        line-height: 19px;
        color: #3a3e47;
        cursor: pointer;

        &:hover {
          background: #ddd2df;
        }

        & + div.alternative {
          margin-left: 4px;
        }
      }

      > div.correct-question-alternative {
        color: #137177;

        &:hover {
          background: #8fe1b0;
        }
      }

      > div.wrong-question-alternative {
        color: #5f061d;

        &:hover {
          background: #ee8899;
        }
      }

      > div.question-without-correct-answer {
        background: #794a7d;
        box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25);
        color: #ffffff;

        &:hover {
          background: #794a7d;
          box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25);
        }
      }

      > div.correct-alternative {
        background: #56b870;
        box-shadow: inset 0px 1px 0px rgba(19, 113, 119, 0.5);

        &:hover {
          background: #56b870;
          box-shadow: inset 0px 1px 0px rgba(19, 113, 119, 0.5);
        }
      }

      > div.wrong-alternative {
        background: #df1d3d;
        box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25);

        &:hover {
          background: #df1d3d;
          box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.25);
        }
      }

      > div.is-right-alternative-of-wrong-question,
      div.is-right-alternative-of-preview-question {
        // width: 44px;
        // height: 44px;
        border: 2px solid #56b870;
        color: #137177;

        &:hover {
          background: #ffffff;
        }
      }

      > div.is-right-alternative-of-preview-question:hover {
        background: #56b870;
      }

      > div.hover:hover {
        box-shadow: 0;
      }
    }

    > div.canceled {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(250, 250, 252, 0.9);

      > p {
        margin-left: 6px;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
      }

      > p {
        color: #9c9fb0;
      }
    }

    > div.correction-status {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -8px;
      left: -1.2px;
      padding: 2px 4px;
      border-radius: 2px 2px 2px 0px;

      > p {
        margin-left: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
      }
    }

    div.correction-status-awaiting-correction {
      background: #ff9f18;
    }

    div.correction-status-correction-available,
    div.correction-status-correction-preview {
      background: #ced0dd;
    }
  }

  > div.correct-content {
    background: #deedcf;
  }

  > div.wrong-content {
    background: #f6bec7;
  }

  > div.awaiting-correction {
    border: 1.2px solid #ff9f18;
  }

  > div.correction-available,
  div.preview-correction {
    border: 1.2px solid #ced0dd;
  }
`
