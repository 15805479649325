import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Icon } from '../icon'
import { Wrapper } from './select.styles'

export const Select: FunctionComponent<any> = ({
  label,
  options,
  selected,
  onChange
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false)
      }
    }

    window.addEventListener('click', clickHandler)

    return () => {
      window.removeEventListener('click', clickHandler)
    }
  }, [])

  const onChangeSelectedOption = (option: any) => {
    onChange(option)
    setOpen(false)
  }

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.style.bottom = `-${bodyRef.current.scrollHeight + 8}px`
    }
  }, [])

  return (
    <Wrapper ref={ref} open={!!open}>
      {selected ? (
        <div className="select-header" onClick={() => setOpen(!open)}>
          <span className="selected-label">{selected.title}</span>

          <img src={selected.icon as any} />
        </div>
      ) : (
        <div className="select-header" onClick={() => setOpen(!open)}>
          <span className="selected-label">{label}</span>

          <Icon name="chevronDown" color="#fff" size={16} />
        </div>
      )}

      <div className="select-body" ref={bodyRef}>
        {options &&
          options.map((option: any, index: number) => (
            <div
              key={index}
              className="select-option"
              onClick={() => {
                onChangeSelectedOption(option)
              }}
            >
              <img src={option.icon as any} />

              <span>{option.title}</span>
            </div>
          ))}
      </div>
    </Wrapper>
  )
}
