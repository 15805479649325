import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding-bottom: 16px;

  div.button-container {
    max-width: 392px;
    width: 100%;
    padding: 0 16px;
  }

  .description {
    margin: 0;
    font-style: normal;
    margin: 0 0 16px;
    padding: 0 16px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #bca5be;
    text-align: center;
  }
`

export const TitleWrapper = styled.div<{ titleWithAction: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 0;

  > .title {
    margin: 0;
    font-style: normal;
    padding: 16px 16px 8px;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    text-transform: uppercase;
  }


  ${({ titleWithAction }) =>
    !!titleWithAction &&
    css`
      cursor: pointer;
    `}
`
