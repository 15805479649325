import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  .gabarito-tabs-container {
    .gabarito-tab-panels {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .tab-more > * {
      width: 100%;

      display: none;

      margin-bottom: 4px;

      ${MediaQueries.medium} {
        display: block;
      }
    }

    .tab-panels {
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      border-radius: 4px 4px 0px 0px;
      overflow: hidden;

      > .gabarito-tab-panel {
        width: 100%;

        ${MediaQueries.medium} {
          max-width: 108px;
        }
      }
    }

    .tabs > * {
      width: 100%;
    }
  }
`
