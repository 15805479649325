// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M16 5c-6.075 0-11 4.925-11 11s4.925 11 11 11c6.075 0 11-4.925 11-11s-4.925-11-11-11zM3 16c0-7.18 5.82-13 13-13s13 5.82 13 13c0 7.18-5.82 13-13 13s-13-5.82-13-13z"></path>
    <path d="M16 9c0.552 0 1 0.448 1 1v7c0 0.552-0.448 1-1 1s-1-0.448-1-1v-7c0-0.552 0.448-1 1-1z"></path>
    <path d="M16 23c0.828 0 1.5-0.672 1.5-1.5s-0.672-1.5-1.5-1.5c-0.828 0-1.5 0.672-1.5 1.5s0.672 1.5 1.5 1.5z"></path>
  </svg>
)
