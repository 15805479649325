import React, { HTMLAttributes } from 'react'

import loadable from '@loadable/component'

const Footer = loadable(() => import('components/Footer'))
const WhatsDoubts = loadable(() => import('../whatssapp'))
const SEO = loadable(() => import('components/seo'))
const NavBar = loadable(() => import('components/Navbar'))

import * as S from './styles'

interface IProps extends HTMLAttributes<HTMLElement> {
  children?: React.ReactNode
  mode?: string
  theme?: 'christmas' | 'default'
  disableFixed?: boolean
  screen?: 'home' | 'gabaritos'
}

const Layout = ({
  children,
  mode = 'proenem',
  theme = 'default',
  screen = 'home',
  ...rest
}: IProps) => {
  return (
    <>
      <S.Wrapper {...rest}>
        <SEO
          title="Gabarito ENEM 2023 - PROENEM"
          description="Gabarito ENEM extraoficial, simule sua aprovação e descubra sua nota, com margem de acerto de 94% e conquiste a faculdade dos seus sonhos."
        />
        <NavBar mode="proenem" slot="navbar" />
        {children}
        <WhatsDoubts />
        <Footer />
      </S.Wrapper>
    </>
  )
}

export default Layout
