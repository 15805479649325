import React from 'react'
import FormSection from 'components/FormsSection/formBase'
import { saveContact } from 'services/active_campaign'
import { FormBoxRetaFinal } from 'component-library/form-box-reta-final'
import { Modal } from 'component-library/modal'
import { Icon } from 'component-library/icon'

export interface IFormData {
  title: string
  event: string
  labelButton: string
}

export interface IResultFormData {
  name: string
  email: string
  event: string
  whatsapp: string
}

interface IProps {
  formData: IFormData
  isOpened: boolean
  handleClose: () => void
  onSuccess: (data: IResultFormData) => void
  onFailure: (message: string) => void
}

const ModalLead = ({
  formData,
  isOpened,
  handleClose,
  onSuccess,
  onFailure
}: IProps) => {
  const handleSubmittedFormData = async (e: any) => {
    try {
      const { name, email, whatsapp, event } = e

      const resp = await saveContact({
        email,
        name,
        phone: whatsapp
      })

      if (!resp) {
        throw new Error('Erro ao cadastrar no active campaign')
      }

      const userForm: IResultFormData = {
        name,
        email,
        event,
        whatsapp
      }

      onSuccess(userForm)
    } catch (err: any) {
      console.error(err)
      onFailure(err)
    } finally {
      handleClose()
    }
  }

  return isOpened ? (
    <Modal
      handleOutsideClick={handleClose}
      closeIconElement={
        <Icon name="close" color="#fff" size={24} handleClick={handleClose} />
      }
      content={
        <FormBoxRetaFinal>
          <FormSection
            formData={formData}
            onSubmitForm={handleSubmittedFormData}
          />
        </FormBoxRetaFinal>
      }
    />
  ) : null
}

export default ModalLead
