// Dependencies
import React, { FunctionComponent } from 'react'
import { Container, Wrapper } from './label.styles'
import { Icon } from 'component-library/icon/icon.component'

export const Label: FunctionComponent<any> = ({
  label,
  slug,
  isSelected,
  icon,
  onLabelClick
}) => {
  return (
    <Container>
      <Wrapper selected={isSelected} onClick={() => onLabelClick(slug)}>
        {(isSelected || icon) && <Icon name={icon ? icon : 'check'} size={14} color='#505462'  />}
        <p className="label">{label}</p>
      </Wrapper>
    </Container>
  )
}
