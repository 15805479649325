// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { ModalProps } from './modal.types'

// Styles
import {
  Container,
  FullContentWrapper,
  FooterWrapper,
  TitleAndCloseWrapper,
  ContentWrapper,
  TitleWrapper,
  Title
} from './modal.styles'

// Components
import { ScreenWidthRender } from 'components/utilities/screen-width-render'
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import { Overlay } from 'component-library/overlay'

export const Modal: FunctionComponent<ModalProps> = ({
  title,
  content,
  footerElement,
  closeIconElement,
  handleOutsideClick
}) => {
  return (
    <>
      <Overlay
        handleOutsideClick={() => !!handleOutsideClick && handleOutsideClick()}
      />

      <Container className="gabarito-modal">
        {closeIconElement}

        <FullContentWrapper>
          <ContentWrapper>{content}</ContentWrapper>

          <ScreenWidthRender
            actionAfterRenderingWidth="show"
            renderingWidth={1280}
            content={
              <ConditionallyRender
                shouldRender={!!footerElement}
                content={<FooterWrapper>{footerElement}</FooterWrapper>}
              />
            }
          />
        </FullContentWrapper>

        <ScreenWidthRender
          actionAfterRenderingWidth="hide"
          renderingWidth={1280}
          content={
            <ConditionallyRender
              shouldRender={!!footerElement}
              content={<FooterWrapper>{footerElement}</FooterWrapper>}
            />
          }
        />
      </Container>
    </>
  )
}
