// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M15.293 9.293c0.39-0.391 1.024-0.391 1.414 0l10 10c0.39 0.39 0.39 1.024 0 1.414s-1.024 0.39-1.414 0l-9.293-9.293-9.293 9.293c-0.391 0.39-1.024 0.39-1.414 0s-0.391-1.024 0-1.414l10-10z"></path>
  </svg>
)
