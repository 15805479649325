import { Icon } from 'component-library/icon'
import React, { FunctionComponent } from 'react'
import { Container } from './question.styles'

export const AlternativeOptions = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E'
} as const

export type AlternativeOption =
  (typeof AlternativeOptions)[keyof typeof AlternativeOptions]

export const Question: FunctionComponent<any> = ({
  questionNumber = '000',
  correctAlternative,
  selectedAlternative,
  isCanceled,
  hasCorrectionAvailable,
  hasVisibleCorrection,
  onQuestionClick
}) => {
  const rightQuest: boolean =
    !isCanceled &&
    !!selectedAlternative &&
    !!correctAlternative &&
    selectedAlternative === correctAlternative
  //------------------------------

  const wrongQuestion: boolean =
    !isCanceled &&
    !!selectedAlternative &&
    !!correctAlternative &&
    selectedAlternative !== correctAlternative
  //------------------------------

  const awaitingCorrection: boolean =
    !isCanceled &&
    hasCorrectionAvailable !== undefined &&
    !hasCorrectionAvailable
  //------------------------------

  const correctionAvailable: boolean =
    !isCanceled &&
    hasCorrectionAvailable !== undefined &&
    hasCorrectionAvailable &&
    !selectedAlternative &&
    !hasVisibleCorrection
  //------------------------------

  const previewCorrection: boolean =
    !isCanceled &&
    hasCorrectionAvailable !== undefined &&
    hasCorrectionAvailable &&
    !selectedAlternative &&
    hasVisibleCorrection
  //------------------------------

  const questionWithoutCorrectAnswer = !isCanceled && !correctAlternative

  const alternativeConfigs: any[] = [
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.A,
      alternativeLabel: AlternativeOptions.A,
      isRightAlternative: correctAlternative === AlternativeOptions.A
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.B,
      alternativeLabel: AlternativeOptions.B,
      isRightAlternative: correctAlternative === AlternativeOptions.B
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.C,
      alternativeLabel: AlternativeOptions.C,
      isRightAlternative: correctAlternative === AlternativeOptions.C
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.D,
      alternativeLabel: AlternativeOptions.D,
      isRightAlternative: correctAlternative === AlternativeOptions.D
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.E,
      alternativeLabel: AlternativeOptions.E,
      isRightAlternative: correctAlternative === AlternativeOptions.E
    }
  ]

  const selectIcon = () => {
    if (hasVisibleCorrection) {
      return 'eye'
    }

    if (hasCorrectionAvailable) {
      return 'doubleCheck'
    }

    return 'clock'
  }

  const setDescriptionToQuestion = () => {
    if (hasVisibleCorrection) {
      return 'Pré-visualizar'
    }

    if (hasCorrectionAvailable) {
      return 'Correção Disponível'
    }

    return 'Aguardando Correção'
  }

  const contentClasses = [
    'content',
    rightQuest && 'correct-content',
    wrongQuestion && 'wrong-content',
    awaitingCorrection && 'awaiting-correction',
    correctionAvailable && 'correction-available',
    previewCorrection && 'preview-correction'
  ]
    .filter(Boolean)
    .join(' ')

  const questionNumberClasses = [
    'question-number',
    rightQuest && 'correct-question-number',
    wrongQuestion && 'wrong-question-number',
    awaitingCorrection && 'question-number-awaiting-correction'
  ]
    .filter(Boolean)
    .join(' ')

  const alternativeClasses = (
    isSelectedAlternative: boolean,
    isRightAlternative: boolean
  ) =>
    [
      'alternative',
      rightQuest && isSelectedAlternative && 'correct-question-alternative',
      wrongQuestion && isSelectedAlternative && 'wrong-question-alternative',
      questionWithoutCorrectAnswer &&
        isSelectedAlternative &&
        'question-without-correct-answer',
      rightQuest && isSelectedAlternative && 'correct-alternative',
      wrongQuestion && isSelectedAlternative && 'wrong-alternative',
      isRightAlternative &&
        wrongQuestion &&
        'is-right-alternative-of-wrong-question',
      !isSelectedAlternative && 'hover',
      previewCorrection &&
        isRightAlternative &&
        'is-right-alternative-of-preview-question'
    ]
      .filter(Boolean)
      .join(' ')

  const correctionStatusClasses = [
    'correction-status',
    awaitingCorrection && 'correction-status-awaiting-correction',
    correctionAvailable && 'correction-status-correction-available',
    previewCorrection && 'correction-status-correction-preview'
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <Container>
      <div className={contentClasses}>
        <div className={questionNumberClasses}>{questionNumber}</div>

        <div className="alternatives">
          {alternativeConfigs.map((alternative) => (
            <div
              key={alternative.alternativeLabel}
              className={alternativeClasses(
                alternative.isSelectedAlternative,
                alternative.isRightAlternative
              )}
              onClick={() => {
                const event = new CustomEvent('questionClick', {
                  detail: {
                    selectedAlternative: alternative.alternativeLabel,
                    questionNumber: questionNumber
                  }
                })

                onQuestionClick(event)
              }}
            >
              {alternative.alternativeLabel}
            </div>
          ))}
        </div>

        {(awaitingCorrection || correctionAvailable || previewCorrection) && (
          <div className={correctionStatusClasses}>
            <Icon name={selectIcon()} size={8} color="#9c9fb0" />
            <p>{setDescriptionToQuestion()}</p>
          </div>
        )}

        {isCanceled && (
          <div className="canceled">
            <Icon name="warningCircle" color="#9c9fb0" />
            <p>Questão anulada!</p>
          </div>
        )}
      </div>
    </Container>
  )
}
