// Assets
import ballPink from '../../../static/images/svg/gabaritos/ball-pink-icon.svg';
import ballBlue from '../../../static/images/svg/gabaritos/ball-blue-icon.svg';
import ballYellow from '../../../static/images/svg/gabaritos/ball-yellow-icon.svg';
import ballGray from '../../../static/images/svg/gabaritos/ball-gray-icon.svg';
import ballWhite from '../../../static/images/svg/gabaritos/ball-white-icon.svg';
import flagSpanish from '../../../static/images/svg/gabaritos/flag-spanish-icon.svg';
import flagUsa from '../../../static/images/svg/gabaritos/flag-usa-icon.svg';

const defaultUserAlternatives: string = '??????????????????????????????????????????????????????????????????????????????????????????';

const defaultResponses: string = '                                                                                          ';

const sisuCard = {
  title: 'Quer simular o SISU?',
  description:
    'Utilize as notas com TRI e simule as suas chances de entrar na universidade com nosso simulador do SISU.',
  buttonLabel: 'CLIQUE AQUI!'
};

const testColors = [
  {
    icon: ballYellow,
    key: 'amarela',
    title: 'Amarela'
  },
  {
    icon: ballPink,
    key: 'rosa',
    title: 'Rosa'
  },
  {
    icon: ballBlue,
    key: 'azul',
    title: 'Azul'
  },
  {
    icon: ballGray,
    key: 'cinza',
    title: 'Cinza'
  },
  {
    icon: ballWhite,
    key: 'branca',
    title: 'Branca'
  }
];

const testLanguages = [
  {
    icon: flagUsa,
    key: 'ingles',
    title: 'Inglês'
  },
  {
    icon: flagSpanish,
    key: 'espanhol',
    title: 'Espanhol'
  }
];

const alternatives = ['A', 'B', 'C', 'D', 'E'];

export const data = {
  defaultUserAlternatives,
  defaultResponses,
  alternatives,
  sisuCard,
  testColors,
  testLanguages,
};
