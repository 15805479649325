// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M16 0c2.101 0 4.182 0.414 6.123 1.218s3.705 1.983 5.191 3.468c1.486 1.486 2.664 3.25 3.468 5.191s1.218 4.022 1.218 6.123c0 4.244-1.686 8.313-4.686 11.314s-7.070 4.686-11.314 4.686c-2.101 0-4.182-0.414-6.123-1.218s-3.705-1.983-5.191-3.468c-3.001-3.001-4.686-7.070-4.686-11.314s1.686-8.313 4.686-11.314c3.001-3.001 7.070-4.686 11.314-4.686zM9.6 9.136v5.344l11.424 1.52-11.424 1.52v5.344l16-6.864-16-6.864z"></path>
  </svg>
)
