// Dependencies
import React, { useEffect, useMemo, useRef, useState } from 'react'

// Services
import { saveContact } from 'services/active_campaign'
import { FirebaseService as Service } from '../../services/firebaseService'

// Utils
import { data } from './gabaritos.data'
import {
  Filters,
  EditionsUnion,
  EditionDaysUnion,
  EditionDayVersionItemsUnion,
  EditionDayVersionsUnion,
  ISubject,
  IUser,
  ITRINote,
  IEnemEditionData
} from './gabaritos.model'
import { getResults } from './gabaritos.helpers'

// Components
import {
  clearUserLocalStorage,
  getUserLocalStorage,
  setUserLocalStorage
} from 'utils/userLocalStorage'

import { IResultFormData } from 'components/ModalLead'

interface IResult {
  total: number
  hits: number
}

interface ITRIResult {
  max: number
  min: number
}

// =====

interface ISubjectResult {
  label: string
  result: IResult
  tri?: ITRIResult
}

interface ITotalResult {
  totalQuestions: number
  totalHits: number
  dayLabel: string
}

// =====

interface IDayResultsArgs {
  subjectResults: ISubjectResult[]
  totalResults: ITotalResult
}

const leadsDatabase = 'pe-gabarito-acessos-2023'

export function useGabaritos() {
  const [filters, setFilters] = useState<Filters | null>(null)

  const [selectedEnemEdition, setSelectedEnemEdition] =
    useState<EditionsUnion | null>(null)
  const [selectedEnemEditionData, setSelectedEnemEditionData] = useState<
    IEnemEditionData[] | null
  >(null)

  const [editionSubjects, setEditionSubjects] = useState<ISubject[] | null>(
    null
  )
  const [selectedEditionDayIndex, setSelectedEditionDayIndex] =
    useState<number>(0)
  const [selectedEditionDayLabel, setSelectedEditionDayLabel] =
    useState<EditionDaysUnion | null>(null)

  const [selectedLanguage, setSelectedLanguage] =
    useState<EditionDayVersionItemsUnion | null>(null)
  const [selectedColor, setSelectedColor] =
    useState<EditionDayVersionsUnion | null>(null)

  const [editionDayResponses, setEditionDayResponses] = useState<string>(
    data.defaultResponses
  )
  const [editionDayResponsesSubjects, setEditionDayResponsesSubjects] =
    useState<string | null>(null)
  const [editionDayResponsesStart, setEditionDayResponsesStart] =
    useState<number>(1)
  const [editionDayResponsesIRT, setEditionDayResponsesIRT] = useState<
    any | null
  >(null)
  const [editionDayUserResponses, setEditionDayUserResponses] =
    useState<string>(data.defaultUserAlternatives)
  const [editionDayUserHits, setEditionDayUserHits] = useState<string>(
    data.defaultUserAlternatives
  )

  const [dayResults, setDayResults] = useState<IDayResultsArgs | null>(null)

  const [userData, setUserData] = useState<IUser | null>(null)

  const [TRINotes, setTRINotes] = useState<ITRINote[] | null>(null)

  const [modalContent, setModalContent] = useState<any>()
  const [modalLayout, setModalLayout] = useState<string>()

  const modalElement = useRef()

  const [bottomSheetIsOpen, setBottomSheetIsOpen] = useState<boolean>(false)

  const [hasVisibleCorrection, setHasVisibleCorrection] =
    useState<boolean>(true)

  useEffect(() => {
    Service.init()
  }, [])

  useEffect(() => {
    ;(async () => {
      const localStorage = getUserLocalStorage()

      if (localStorage?.user?.event === leadsDatabase) {
        setUserData(localStorage.user)
      }

      if (
        !!localStorage?.user?.event &&
        localStorage.user.event !== leadsDatabase
      ) {
        const { name, email, whatsapp } = localStorage.user

        await saveContact({
          name,
          email,
          phone: whatsapp
        })

        const formattedUser = {
          ...localStorage.user,
          event: leadsDatabase
        }

        setUserLocalStorage(formattedUser)

        setUserData(formattedUser)
      }

      const filtersResponse = await Service.get('filters')

      filtersResponse.years.reverse()

      setFilters(filtersResponse)

      const params = window.location.search

      if (params.includes('enem')) {
        const selectEdition = params.split('=')[1] as EditionsUnion
        setSelectedEnemEdition(selectEdition)
      } else {
        setSelectedEnemEdition(filtersResponse.years[0].key as EditionsUnion)
      }
    })()
  }, [])

  useEffect(() => {
    if (!!selectedEnemEdition) {
      setSelectedEditionDayIndex(0)

      setSelectedEditionDayLabel(filters?.days[0].key as EditionDaysUnion)

      // setSelectedEditionDayIndex(selectedEnemEdition === '2023' ? 1 : 0)

      // setSelectedEditionDayLabel(
      //   selectedEnemEdition === '2023'
      //     ? 'dia-2'
      //     : (filters?.days[0].key as EditionDaysUnion)
      // )
      ;(async () => {
        const url = `gabarito/exams/enem/editions/${selectedEnemEdition}`

        const editionFirstDayNameResponse = await Service.get(
          `${url}/days/dia-1/name`
        )

        const editionSecondDayNameResponse = await Service.get(
          `${url}/days/dia-2/name`
        )

        const editionFirstDayVideoUrlResponse = await Service.get(
          `${url}/days/dia-1/videoUrl`
        )

        const editionSecondDayVideoUrlResponse = await Service.get(
          `${url}/days/dia-2/videoUrl`
        )

        setSelectedEnemEditionData([
          {
            name: editionFirstDayNameResponse,
            videoUrl: editionFirstDayVideoUrlResponse
          },
          {
            name: editionSecondDayNameResponse,
            videoUrl: editionSecondDayVideoUrlResponse
          }
        ])
      })()
    }
  }, [selectedEnemEdition])

  useEffect(() => {
    setSelectedColor(null)

    setSelectedLanguage(null)

    setEditionDayResponses(data.defaultResponses)

    setEditionDayUserHits(data.defaultUserAlternatives)

    setEditionDayUserResponses(data.defaultUserAlternatives)

    setEditionDayResponsesIRT(null)

    setDayResults(null)
  }, [selectedEnemEdition, selectedEditionDayLabel])

  useEffect(() => {
    if (!!selectedEnemEdition) {
      ;(async () => {
        const editionSubjectsResponse = await Service.get(
          `gabarito/exams/enem/editions/${selectedEnemEdition}/subjects`
        )

        setEditionSubjects(editionSubjectsResponse)
      })()

      if (!!userData && !!selectedEditionDayLabel) {
        ;(async () => {
          const userDataResponse = await Service.get(
            `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
              '.',
              ''
            )}`
          )

          if (selectedEditionDayLabel === 'dia-1') {
            if (!!userDataResponse?.version) {
              setSelectedLanguage(userDataResponse.version)
            } else {
              Service.update(
                `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
                  '.',
                  ''
                )}`,
                { version: 'ingles' }
              )

              setSelectedLanguage('ingles')
            }
          }

          if (!!userDataResponse?.item) {
            setSelectedColor(userDataResponse.item)
          } else {
            Service.update(
              `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
                '.',
                ''
              )}`,
              { item: 'amarela' }
            )

            setSelectedColor('amarela')
          }

          if (!!userDataResponse?.studentAnswerKeys) {
            setEditionDayUserResponses(userDataResponse.studentAnswerKeys)
          } else {
            setEditionDayUserResponses(data.defaultUserAlternatives)
          }

          if (!!userDataResponse?.acertos) {
            setEditionDayUserHits(userDataResponse.acertos)
          } else {
            setEditionDayUserHits(data.defaultUserAlternatives)
          }
        })()
      }
    }
  }, [selectedEnemEdition, selectedEditionDayLabel, userData])

  const setDefaultTRINotes = () => {
    if (!!editionSubjects) {
      const TRINotes = editionSubjects.map((subject: ISubject) => ({
        subjectId: subject.id,
        subjectSlug: subject.slug,
        note: null
      }))

      setTRINotes(TRINotes)
    }
  }

  useEffect(() => {
    setDefaultTRINotes()
  }, [selectedEnemEdition, userData])

  useEffect(() => {
    if (!TRINotes) {
      setDefaultTRINotes()
    }
  }, [editionSubjects])

  useEffect(() => {
    if (
      (!!selectedLanguage || selectedEditionDayLabel === 'dia-2') &&
      !!selectedColor &&
      !!selectedEnemEdition &&
      !!selectedEditionDayLabel
    ) {
      ;(async () => {
        const url = `gabarito/exams/enem/editions/${selectedEnemEdition}/days/${selectedEditionDayLabel}/versions/${selectedColor}/items/${
          selectedEditionDayLabel === 'dia-2' ? 'geral' : selectedLanguage
        }`

        const dayResponse = await Service.get(url)

        if (!!dayResponse) {
          setEditionDayResponses(dayResponse.answerKey)

          setEditionDayResponsesSubjects(dayResponse.subjects)

          setEditionDayResponsesStart(dayResponse.start)

          if (!!dayResponse?.irt) {
            setEditionDayResponsesIRT(dayResponse.irt)
          }

          Service.onChange(`${url}/answerKey`, (value) => {
            setEditionDayResponses(value)
          })

          Service.onChange(`${url}/irt`, (value) => {
            if (!!value) {
              setEditionDayResponsesIRT(value)
            }
          })
        }
      })()
    }
  }, [selectedLanguage, selectedColor])

  useEffect(() => {
    if (
      !!selectedEnemEdition &&
      !!userData &&
      !!selectedEditionDayLabel &&
      !!selectedColor &&
      !!editionDayUserResponses &&
      !!editionDayResponsesSubjects &&
      (selectedEditionDayLabel === 'dia-2' || !!selectedLanguage) &&
      !!editionDayUserHits
    ) {
      const acertosUrl = `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
        '.',
        ''
      )}`

      let respostasUrl = `gabarito/respostas/enem/editions/${selectedEnemEdition}/days/${selectedEditionDayLabel}/versions/${selectedColor}/items/`

      if (selectedEditionDayLabel === 'dia-1') {
        respostasUrl = respostasUrl.concat(`${selectedLanguage}`)
      } else {
        respostasUrl = respostasUrl.concat(`geral`)
      }

      const newResults = {
        studentAnswerKeys: editionDayUserResponses,
        subjects: editionDayResponsesSubjects,
        acertos: editionDayUserHits
      }

      Service.update(acertosUrl, newResults)

      Service.update(respostasUrl, {
        [`${userData.email.replaceAll('.', '')}`]:
          editionDayUserResponses.replaceAll('?', ' ')
      })
    }
  }, [
    editionDayUserResponses,
    editionDayUserHits,
    selectedColor,
    selectedLanguage
  ])

  useEffect(() => {
    if (
      !!selectedEditionDayLabel &&
      !!editionDayResponses &&
      !!editionDayResponsesSubjects &&
      !!editionSubjects &&
      (!!selectedLanguage || selectedEditionDayLabel === 'dia-2') &&
      !!selectedColor
    ) {
      const { TRINotesTmp, dayResultsTmp } = getResults(
        selectedEditionDayLabel,
        editionDayResponses,
        editionDayUserResponses,
        editionDayResponsesSubjects,
        editionSubjects,
        TRINotes,
        editionDayResponsesIRT
      )

      setTRINotes(TRINotesTmp)

      setDayResults(dayResultsTmp)
    } else {
      setDayResults(null)
    }
  }, [
    editionDayResponses,
    editionDayResponsesSubjects,
    editionDayUserResponses,
    editionSubjects,
    selectedColor,
    selectedEditionDayLabel,
    selectedLanguage,
    editionDayResponsesIRT
  ])

  useEffect(() => {
    if (!!userData && !!selectedEnemEdition && !!selectedEditionDayLabel) {
      let version: string | null = null
      let item: string | null = null
      let studentAnswerKeys: string | null = null

      let dayResponse: any | null = null

      ;(async () => {
        const userDataResponse = await Service.get(
          `gabarito/acertos/enem/${selectedEnemEdition}/${
            selectedEditionDayLabel === 'dia-2' ? 'dia-1' : 'dia-2'
          }/${userData.email.replaceAll('.', '')}`
        )

        if (!!userDataResponse?.version) {
          version = userDataResponse.version
        }

        if (!!userDataResponse?.item) {
          item = userDataResponse.item
        }

        if (!!userDataResponse?.studentAnswerKeys) {
          studentAnswerKeys = userDataResponse.studentAnswerKeys
        }

        if (!!item && (!!version || selectedEditionDayLabel === 'dia-1')) {
          const url = `gabarito/exams/enem/editions/${selectedEnemEdition}/days/${
            selectedEditionDayLabel === 'dia-2' ? 'dia-1' : 'dia-2'
          }/versions/${item}/items/${
            selectedEditionDayLabel === 'dia-2' ? version : 'geral'
          }`

          dayResponse = await Service.get(url)
        }

        if (
          !!selectedEditionDayLabel &&
          !!editionSubjects &&
          !!dayResponse &&
          !!dayResponse?.answerKey &&
          !!dayResponse?.subjects &&
          !!studentAnswerKeys &&
          !!item &&
          (!!version || selectedEditionDayLabel === 'dia-1')
        ) {
          const { TRINotesTmp, dayResultsTmp } = getResults(
            selectedEditionDayLabel,
            dayResponse.answerKey,
            studentAnswerKeys,
            dayResponse.subjects,
            editionSubjects,
            TRINotes,
            dayResponse?.irt || null
          )

          setTRINotes(TRINotesTmp)
        }
      })()
    }
  }, [selectedEditionDayLabel, selectedEnemEdition, userData, dayResults])

  const colorOptions = useMemo(() => {
    const colors = data.testColors

    if (selectedEditionDayLabel === 'dia-1') {
      return colors.filter((color) => color.key !== 'cinza')
    } else {
      return colors.filter((color) => color.key !== 'branca')
    }
  }, [selectedEditionDayLabel])

  const selectSelectedColor = useMemo(() => {
    if (!!selectedEditionDayLabel) {
      const selectedColorTmp = colorOptions.find((testColors) => {
        return testColors.key === selectedColor
      })

      return selectedColorTmp
    }

    return undefined
  }, [selectedColor, selectedEditionDayLabel, colorOptions])

  const selectSelectedLanguage = useMemo(() => {
    if (!!selectedEditionDayLabel) {
      const selectedLanguageTmp = data.testLanguages.find(
        (testLanguages) => testLanguages.key === selectedLanguage
      )

      return selectedLanguageTmp
    }

    return undefined
  }, [selectedLanguage, selectedEditionDayLabel])

  const handleOpeningSisuSite = () => {
    let url = 'https://sisu.proenem.com.br'

    if (!!TRINotes) {
      TRINotes.map((TRINote) => {
        if (!!TRINote.note) {
          const subjectNote = `${TRINote.subjectSlug}=${TRINote.note}`

          if (!url.includes('?')) {
            url = url.concat(`?${subjectNote}`)
          } else {
            url = url.concat(`&${subjectNote}`)
          }
        }
      })
    }

    window.open(url, '_blank')
  }

  const handleSelectEnemEdition = (edition: string) => {
    window.scrollTo(0, 0)

    setSelectedEnemEdition(edition as EditionsUnion)
    setHasVisibleCorrection(true)
  }

  const handleSelectTab = (editionDay: EditionDaysUnion, index: number) => {
    const editionDayResponsesStartTmp = editionDay === 'dia-1' ? 1 : 91

    setEditionDayResponsesStart(editionDayResponsesStartTmp)

    setSelectedEditionDayIndex(index)

    setSelectedEditionDayLabel(editionDay)

    setHasVisibleCorrection(true)
  }

  const handleSelectColor = (event: any) => {
    if (!!userData) {
      Service.update(
        `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
          '.',
          ''
        )}`,
        { item: event.key }
      )

      setSelectedColor(event.key as EditionDayVersionsUnion)
    } else {
      handleModalTrigger()
    }
  }

  const handleSelectLanguage = (event: CustomEvent) => {
    if (!!userData) {
      Service.update(
        `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
          '.',
          ''
        )}`,
        { version: event.detail }
      )

      setSelectedLanguage(event.detail as EditionDayVersionItemsUnion)
    } else {
      handleModalTrigger()
    }
  }

  const handleCopyToClipboard = () => {
    if (
      !!editionDayResponses &&
      !!selectedEnemEdition &&
      !!selectedEditionDayLabel &&
      !!editionDayResponsesStart &&
      !!selectedColor &&
      (!!selectedLanguage || selectedEditionDayLabel === 'dia-2')
    ) {
      const editedAnswers = editionDayResponses
        .split('')
        .map((response, index) => {
          return `${editionDayResponsesStart + index}-${response}, `
        })

      const copyWithLanguage = `ENEM ${selectedEnemEdition} - ${selectedEditionDayLabel} - ${selectedColor} - ${selectedLanguage} : ${editedAnswers.join(
        ''
      )}`

      const copy = `ENEM ${selectedEnemEdition} - ${selectedEditionDayLabel} - ${selectedColor} : ${editedAnswers.join(
        ''
      )}`

      const textField = document.createElement('textarea')

      textField.innerText = !!selectedLanguage ? copyWithLanguage : copy

      document.body.appendChild(textField)

      textField.select()

      document.execCommand('copy')

      textField.remove()

      alert('Gabarito copiado para a área de transferência!')
    }
  }

  const hasUnansweredEditionDayQuestions = useMemo(() => {
    if (!!editionDayResponses && !!editionDayResponses.length) {
      return !!editionDayResponses
        .split('')
        .find((response) => response === '?')
    }

    return true
  }, [editionDayResponses])

  const handleOpeningWhatsapp = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=5521995481656&text&app_absent=0',
      '_blank'
    )
  }

  const handleSubmittedFormData = async (e: IResultFormData) => {
    const { name, email, whatsapp, event } = e

    const userForm = {
      name,
      email,
      event,
      whatsapp
    }

    setUserLocalStorage(userForm)
    setUserData(userForm)
  }

  const handleModalContent = () => {
    setModalContent({
      title: `ACESSE AGORA O GABARITO 2023!`,
      labelButton: 'Acessar o gabarito',
      event: leadsDatabase
    })

    return true
  }

  const handleModalTrigger = () => {
    const localStorageData = getUserLocalStorage()

    if (!localStorageData) {
      handleModalContent()
      return
    }

    const { user } = localStorageData

    if (Object.keys(user).length === 0) {
      handleModalContent()
    } else {
      saveContact({
        ...user,
        phone: user.whatsapp
      })
    }
  }

  const handleSelectQuestion = (event: CustomEvent, index: number) => {
    if (!!selectedEditionDayLabel && !!userData) {
      const { selectedAlternative } = event.detail

      const editionDayResponsesTmp = editionDayResponses.split('')

      const editionDayUserHitsTmp = editionDayUserHits.split('')

      const editionDayUserResponsesTmp = editionDayUserResponses.split('')

      editionDayUserResponsesTmp[index] =
        selectedAlternative === editionDayUserResponsesTmp[index]
          ? '?'
          : selectedAlternative

      editionDayUserHitsTmp[index] =
        selectedAlternative === editionDayResponsesTmp[index] ? '1' : '0'

      setEditionDayUserHits(editionDayUserHitsTmp.join(''))

      setEditionDayUserResponses(editionDayUserResponsesTmp.join(''))
    } else {
      handleModalTrigger()
    }
  }

  const handleSignOut = () => {
    clearUserLocalStorage()

    setSelectedColor(null)

    setSelectedLanguage(null)

    setEditionDayUserResponses(data.defaultUserAlternatives)

    setDayResults(null)

    setUserData(null)
  }

  const getEditionLabel = useMemo(() => {
    if (!!filters && !!selectedEnemEdition) {
      const editionLabel = filters.years.find(
        (year) => year.key === selectedEnemEdition
      )

      return editionLabel?.value
    }
  }, [selectedEnemEdition])

  return {
    filters,
    selectedEnemEdition,
    selectedEnemEditionData,
    userData,
    handleSignOut,
    getEditionLabel,
    modalElement,
    modalLayout,
    setModalLayout,
    modalContent,
    selectedEditionDayIndex,
    handleSelectTab,
    dayResults,
    colorOptions,
    selectSelectedColor,
    handleSelectColor,
    selectedEditionDayLabel,
    selectSelectedLanguage,
    handleSelectLanguage,
    editionDayResponses,
    selectedLanguage,
    selectedColor,
    editionDayUserResponses,
    editionDayResponsesStart,
    handleSelectQuestion,
    handleSelectEnemEdition,
    handleOpeningWhatsapp,
    handleCopyToClipboard,
    hasUnansweredEditionDayQuestions,
    handleOpeningSisuSite,
    bottomSheetIsOpen,
    setBottomSheetIsOpen,
    hasVisibleCorrection,
    handleSubmittedFormData,
    setModalContent,
    setHasVisibleCorrection
  }
}
