import styled, { css } from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  width: 100%;

  ${MediaQueries.medium} {
    width: max-content;
  }
`

export const Wrapper = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ced0dd;
  background: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #505462;
  cursor: pointer;

  ${MediaQueries.medium} {
    width: max-content;
  }

  > .gabarito-icon {
    margin-right: 8px;
  }

  ${({ selected }) =>
    !!selected &&
    css`
      border: 0;
      background: #f0f1f7;
      color: #091e5f;
    `}
`
