import React, { useRef } from 'react'
import { IFormData } from 'components/ModalLead'
import { Container } from './formBase.styles'
import { Button } from 'component-library/button'
import { Input } from 'component-library/input'

interface Props {
  formData: IFormData
  onSubmitForm: (ev: CustomEvent) => void
}

const FormBase = ({ formData, onSubmitForm }: Props) => {
  const emailRef = useRef({ valid: false })
  const nameRef = useRef({ valid: false })
  const phoneRef = useRef({ valid: false })

  return (
    <Container
      onSubmit={(event) => {
        event.preventDefault()

        const isEmailValid = emailRef.current.valid
        const isNameValid = nameRef.current.valid
        const isPhoneValid = phoneRef.current.valid

        let formData = {}

        document
          .querySelectorAll('.gabarito-modal-input > input')
          .forEach((element: any) => {
            formData = {
              ...formData,
              [element.name]: element.value
            }
          })

        if (isEmailValid && isNameValid && isPhoneValid) {
          onSubmitForm(formData as any)
        }
      }}
    >
      <Input
        validation="name"
        errorMsg={'Ops! Mínimo de 3 caracteres!'}
        successMsg={'Nome válido!'}
        label="Nome"
        label-for="name"
        placeholder="Digite seu nome"
        name="name"
        id="name"
        type="text"
        required
        handleStatus={(status) => {
          if (status?.includes('positive')) nameRef.current.valid = true
          else nameRef.current.valid = false
        }}
      />

      <Input
        validation="email"
        errorMsg={'Ops! Formato de e-mail inválido!'}
        successMsg={'E-mail válido!'}
        label="E-mail"
        label-for="email"
        placeholder="Digite seu e-mail"
        name="email"
        id="email"
        type="email"
        required
        handleStatus={(status) => {
          if (status?.includes('positive')) emailRef.current.valid = true
          else emailRef.current.valid = false
        }}
      />

      <Input
        validation="cel"
        errorMsg={'Ops! Seu número deve ter 11 caracteres'}
        successMsg={'Whatsapp válido!'}
        label="Whatsapp"
        label-for="whatsapp"
        placeholder="Digite seu whatsapp"
        name="whatsapp"
        id="whatsapp"
        type="number"
        required
        handleStatus={(status) => {
          if (status?.includes('positive')) phoneRef.current.valid = true
          else phoneRef.current.valid = false
        }}
      />

      <Input
        name="event"
        id="event"
        type="text"
        style={{ display: 'none' }}
        value={formData.event}
      />

      <Button variant='primary' isFullyAdaptative type="submit" label={formData.labelButton} />
    </Container>
  )
}

export default FormBase
