// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M32 5.207c-1.177 0.527-2.457 0.907-3.776 1.054 1.369-0.845 2.395-2.18 2.884-3.754-1.285 0.794-2.693 1.351-4.161 1.646-0.614-0.681-1.356-1.224-2.18-1.594s-1.713-0.56-2.612-0.558c-3.634 0-6.557 3.059-6.557 6.814 0 0.527 0.062 1.054 0.162 1.562-5.442-0.296-10.295-2.996-13.521-7.129-0.588 1.043-0.896 2.23-0.892 3.439 0 2.364 1.158 4.449 2.923 5.676-1.040-0.043-2.056-0.339-2.965-0.867v0.084c0 3.311 2.254 6.055 5.257 6.686-0.564 0.152-1.144 0.23-1.727 0.232-0.427 0-0.831-0.044-1.238-0.104 0.831 2.7 3.25 4.661 6.13 4.725-2.254 1.833-5.076 2.912-8.141 2.912-0.55 0-1.058-0.020-1.584-0.084 2.907 1.937 6.357 3.055 10.072 3.055 12.060 0 18.659-10.377 18.659-19.383 0-0.296 0-0.591-0.019-0.887 1.277-0.971 2.396-2.173 3.288-3.523z"></path>
  </svg>
)
