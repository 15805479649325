import styled from 'styled-components'

export const Container = styled.div`
  > div.bar-container {
    width: 100%;
    height: 8px;
    background: #e7e9f1;
    border-radius: 50px;
    overflow: hidden;
  }
`

type ProgressStyleProps = {
  progress: number;
}

export const Progress = styled.div<ProgressStyleProps>`
  width: ${({ progress }) => `${progress}%`};
  height: 8px;
  transition: all 0.3s;
  background: #56b870;
`;
