// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M15.993 0c-8.819 0-15.993 7.176-15.993 15.995 0 8.827 7.175 16.005 15.993 16.005 8.827 0 16.007-7.18 16.007-16.005 0-8.819-7.18-15.995-16.007-15.995zM7.877 21.872c1.831-0.561 3.735-0.848 5.651-0.851 3.219 0 6.396 0.816 9.189 2.36 0.167 0.091 0.317 0.389 0.387 0.763 0.067 0.373 0.040 0.756-0.069 0.955-0.052 0.095-0.121 0.178-0.205 0.245s-0.18 0.117-0.284 0.147c-0.103 0.030-0.212 0.039-0.319 0.026s-0.21-0.046-0.304-0.099c-2.052-1.126-4.309-1.828-6.637-2.064s-4.68-0.004-6.917 0.686c-0.103 0.033-0.211 0.046-0.318 0.037s-0.212-0.039-0.308-0.089-0.18-0.118-0.249-0.201c-0.069-0.083-0.121-0.179-0.152-0.282-0.143-0.467 0.152-1.507 0.536-1.632zM7.18 15.979c2.069-0.562 4.203-0.847 6.347-0.847 3.859 0 7.689 0.933 11.080 2.701 0.239 0.12 0.413 0.325 0.493 0.576 0.040 0.127 0.055 0.26 0.043 0.392s-0.050 0.261-0.113 0.378c-0.303 0.592-0.657 0.991-0.88 0.991-0.163-0.001-0.322-0.040-0.467-0.115-3.134-1.636-6.62-2.485-10.156-2.472-1.967-0.004-3.925 0.258-5.821 0.78-0.127 0.035-0.259 0.044-0.389 0.027s-0.256-0.058-0.37-0.123-0.214-0.152-0.294-0.256c-0.080-0.104-0.14-0.222-0.174-0.349-0.149-0.563 0.193-1.547 0.7-1.685zM7 12.131c-0.15 0.039-0.306 0.047-0.46 0.025s-0.301-0.075-0.434-0.154c-0.133-0.079-0.249-0.184-0.341-0.309s-0.159-0.266-0.196-0.416c-0.164-0.664 0.251-1.435 0.853-1.587 2.325-0.573 4.71-0.863 7.104-0.865 4.6 0 9.008 1.035 13.099 3.076 0.281 0.14 0.495 0.385 0.595 0.683s0.078 0.623-0.062 0.904c-0.191 0.384-0.604 0.797-1.060 0.797-0.179-0.001-0.355-0.040-0.517-0.116-3.741-1.877-7.87-2.848-12.055-2.835-2.18 0-4.376 0.268-6.527 0.797z"></path>
  </svg>
)
