import React, { FunctionComponent } from 'react'
import {
  Container,
  SelectSessionDetailWrapper
} from './gabarito-results.styles'

export const GabaritoResults: FunctionComponent<any> = ({
  selectSectionTitleComposition,
  selectSectionColorComposition,
  selectSectionLangComposition,
  sectionResultsTitleComposition,
  sectionResultsComposition,
  shouldDisplaySelectSectionLangComposition,
  shouldDisplaySectionResultsTitleComposition,
  shouldDisplaySectionResultsComposition
}) => {
  return (
    <Container>
      <div className="select-section">
        <div className="select-section-title">
          {selectSectionTitleComposition}
        </div>

        <SelectSessionDetailWrapper
          shouldDisplaySelectSectionLangComposition={
            shouldDisplaySelectSectionLangComposition
          }
        >
          <div className="select-section-color">
            {selectSectionColorComposition}
          </div>

          {shouldDisplaySelectSectionLangComposition && (
            <div className="select-section-lang">
              {selectSectionLangComposition}
            </div>
          )}
        </SelectSessionDetailWrapper>
      </div>

      <div className="section-results">
        {shouldDisplaySectionResultsTitleComposition && (
          <div className="section-results-title">
            {sectionResultsTitleComposition}
          </div>
        )}

        {shouldDisplaySectionResultsComposition && (
          <div className="section-result">{sectionResultsComposition}</div>
        )}
      </div>
    </Container>
  )
}
