// Dependencies
import React, { FunctionComponent } from 'react'

// Styles
import { ButtonWrapper, Label } from './button.styles'

// Types
import { ButtonProps } from './button.types'

export const Button: FunctionComponent<ButtonProps> = ({
  label,
  iconElement,
  variant,
  size = 'large',
  isFullyAdaptative,
  ...buttonDefaultProps
}) => {
  return (
    <ButtonWrapper
      className="gabarito-button"
      variant={variant}
      size={size}
      isFullyAdaptative={!!isFullyAdaptative}
      {...buttonDefaultProps}
    >
      {label && <Label>{label}</Label>}

      {iconElement}
    </ButtonWrapper>
  )
}
