// Dependencies
import React, { FunctionComponent } from 'react';

// Types
import { ScreenWidthRenderProps } from './screen-width-render.types';

// Utils
import useWindowSize from 'hooks/useWindowSize';

export const ScreenWidthRender: FunctionComponent<ScreenWidthRenderProps> = ({
  actionAfterRenderingWidth,
  renderingWidth,
  content,
}) => {
  const { width: windowWidth } = useWindowSize();

  if (
    (actionAfterRenderingWidth === 'hide' && windowWidth >= renderingWidth) ||
    (actionAfterRenderingWidth === 'show' && windowWidth < renderingWidth)
  ) {
    return null;
  }

  return <>{content}</>;
};
