// Dependencies
import React, { FunctionComponent } from 'react'

// Styles
import { Container } from './icon.styles'

// Types
import { IconProps } from './icon.types'

// Assets
import { icons } from './icon.data'

export const Icon: FunctionComponent<IconProps> = ({
  size = 16,
  name,
  color,
  handleClick
}) => {
  const Svg = icons[name].source

  return (
    <Container
      className="gabarito-icon"
      size={size}
      color={color}
      hasCursorPointer={!!handleClick}
      onClick={() => !!handleClick && handleClick()}
    >
      <Svg />
    </Container>
  )
}
