import React, { FunctionComponent } from 'react'
import { Container } from './tab-panel.styles'

export const TabPanel: FunctionComponent<any> = ({
  index,
  value,
  onClick,
  content
}) => {
  const classes = ['gabarito-tab-panel', index == value && 'active']
    .filter(Boolean)
    .join(' ')

  return (
    <Container onClick={onClick} className={classes}>
      {content}
    </Container>
  )
}
