export const breakpoints = [
  '450px',
  '768px',
  '1024px',
  '1170px',
  '1440px',
  '1920px',
  '2560px',
  '375px',
  '414px',
  '900px',
  '411px',
  '360px',
  '1500px',
  '812px',
  '1366px'
]

export const extra = {
  laptop: `@media(min-width: 1360px)`,
  xmedium: `@media(min-width: 1080px)`
}

export const BetweenBks = ({ init = 375, end = 640 }) => {
  return `@media(min-width: ${init}px) and (max-width: ${end}px)`
}

export const MediaQueries = {
  small: `@media(min-width: ${breakpoints[0]})`, //450
  medium: `@media(min-width: ${breakpoints[1]})`, //768
  amedium: `@media(min-width: ${breakpoints[2]})`, //1024
  large: `@media(min-width: ${breakpoints[3]})`, //1170
  xlarge: `@media(min-width: ${breakpoints[4]})`, //1440
  fullhd: `@media(min-width: ${breakpoints[5]})`, //1920
  fourk: `@media(min-width: ${breakpoints[6]})`, // 2560
  iphone6: `@media(min-width: ${breakpoints[7]})`, //375
  iphonePlus: `@media(min-width: ${breakpoints[8]})`, //414
  nextxmedium: `@media(min-width: ${breakpoints[9]})`, // 900
  pixel: `@media(min-width: ${breakpoints[10]})`, //411
  sansung: `@media(min-width: ${breakpoints[11]})`, //360
  uxga: `@media(min-width: ${breakpoints[12]})`, //1500
  iphonex: `@media(min-width: ${breakpoints[13]})`, //812
  notebook: `@media(min-width: ${breakpoints[14]})` //1366
}
