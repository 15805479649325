// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <title>close2</title>
    <path d="M25.707 6.293c0.39 0.391 0.39 1.024 0 1.414l-18 18c-0.391 0.39-1.024 0.39-1.414 0s-0.391-1.024 0-1.414l18-18c0.39-0.391 1.024-0.391 1.414 0zM6.293 6.293c0.391-0.391 1.024-0.391 1.414 0l18 18c0.39 0.39 0.39 1.024 0 1.414s-1.024 0.39-1.414 0l-18-18c-0.391-0.391-0.391-1.024 0-1.414z"></path>
  </svg>
)
