// Dependencies
import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }

  .gabarito-info-user-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    > div {
      width: 100%;

      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin-bottom: 5px;

      .title {
        display: block;

        font-style: normal;
        font-weight: bold;

        font-size: 10px;
        line-height: 16px;
        color: #25272d;

        margin: 0px;
        padding: 0px;

        margin-right: 8px;

        ${MediaQueries.medium} {
          font-size: 18px;
          line-height: 16px;
          color: #000000;
        }
      }
    }

    .description {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      white-space: nowrap;

      color: #9c9fb0;

      ${MediaQueries.medium} {
        font-size: 14px;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 53px;
      height: 24.7px;
      background: #ffffff;

      cursor: pointer;

      border: 1px solid #ee8899;
      border-radius: 2px;

      font-style: normal;
      font-weight: normal;

      color: #df1d3d;

      font-size: 10px;
      line-height: 16px;

      gap: 8px;
    }
  }
`
