// Dependencies
import React, { FunctionComponent, useCallback, useEffect } from 'react'

// Types
import { OverlayProps } from './overlay.types'

// Styles
import { Container } from './overlay.styles'

export const Overlay: FunctionComponent<OverlayProps> = ({
  handleOutsideClick
}) => {
  const scrollYWhenOpeningComponent = window.scrollY

  const handleScroll = useCallback(() => {
    window.scrollTo({ top: scrollYWhenOpeningComponent })
  }, [scrollYWhenOpeningComponent])

  function stopScroll() {
    document.body.style.overflow = 'hidden';
  }

  function startScroll() {
    document.body.style.overflow = '';
  }

  useEffect(() => {
    stopScroll()

    return () => {
      startScroll()
    }
  }, [])

  return <Container className="gabarito-overlay" onClick={handleOutsideClick} />
}
