import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 16px;

  ${MediaQueries.medium} {
    width: fit-content;
  }

  &.gabarito-tab-panel {
    width: 100%;
    min-height: 56px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;

    background: #794a7d;

    &.active {
      background: #571d5d;
    }
  }
`
