// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M27.707 8.294c0.39 0.391 0.39 1.024 0 1.414l-14 13.999c-0.39 0.39-1.024 0.39-1.414 0l-7-6.999c-0.391-0.391-0.391-1.024-0-1.414s1.024-0.39 1.414 0l6.293 6.292 13.293-13.292c0.39-0.391 1.024-0.391 1.414 0z"></path>
  </svg>
)
