// Dependencies
import React, { FunctionComponent } from 'react'
import { Container } from './sisu-card-gabarito.styles'

export const SisuCardGabarito: FunctionComponent<any> = ({
  titleComposition,
  descriptionComposition,
  buttonComposition
}) => {
  return (
    <Container>
      <div className="content">
        <div className="title">{titleComposition}</div>

        <div className="description">{descriptionComposition}</div>

        <div className="button-container">
          <div className="button">{buttonComposition}</div>
        </div>
      </div>
    </Container>
  )
}
