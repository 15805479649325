//const N = 256;

export const X: number[] = [
    -31.100951037096512,
    -30.401117779657764,
    -29.825809458081402,
    -29.315556495648897,
    -28.847623300317097,
    -28.410287565216026,
    -27.996515289006134,
    -27.601671075507828,
    -27.222497961270096,
    -26.856597908134813,
    -26.502141097849997,
    -26.157691379788251,
    -25.822095551225734,
    -25.494409967825709,
    -25.173850108958237,
    -24.859754855145845,
    -24.551560528643459,
    -24.248781608052615,
    -23.950996123628848,
    -23.657834409456067,
    -23.368970310885332,
    -23.084114219397521,
    -22.803007488961427,
    -22.525417911510261,
    -22.251136014735937,
    -21.979972005732403,
    -21.711753227242152,
    -21.4463220246819,
    -21.183533945274238,
    -20.923256207880053,
    -20.665366395153397,
    -20.409751329571602,
    -20.156306102538409,
    -19.904933231696582,
    -19.65554192623878,
    -19.408047443678916,
    -19.162370524468338,
    -18.918436893181771,
    -18.676176816885088,
    -18.435524712827875,
    -18.196418798852933,
    -17.958800780939722,
    -17.72261557314406,
    -17.48781104589695,
    -17.254337799208822,
    -17.022148957813458,
    -16.791199985695775,
    -16.561448517793379,
    -16.332854206954514,
    -16.105378584483803,
    -15.878984932819324,
    -15.653638169066099,
    -15.429304738266945,
    -15.205952515425822,
    -14.983550715414763,
    -14.762069809995873,
    -14.541481451277113,
    -14.321758400996547,
    -14.10287446509605,
    -13.884804433103549,
    -13.667524021893771,
    -13.451009823442301,
    -13.235239256227194,
    -13.020190519967282,
    -12.805842553417137,
    -12.59217499496605,
    -12.379168145812671,
    -12.166802935508609,
    -11.955060889683583,
    -11.743924099781964,
    -11.533375194655973,
    -11.323397313874614,
    -11.113974082619859,
    -10.905089588052742,
    -10.696728357042089,
    -10.488875335157653,
    -10.281515866837634,
    -10.07463567664794,
    -9.868220851557275,
    -9.662257824158183,
    -9.456733356769741,
    -9.251634526362552,
    -9.046948710251304,
    -8.842663572504284,
    -8.638767051023046,
    -8.435247345248879,
    -8.232092904455901,
    -8.029292416593492,
    -7.826834797643418,
    -7.624709181459459,
    -7.422904910059545,
    -7.221411524342481,
    -7.02021875520321,
    -6.819316515022294,
    -6.618694889506886,
    -6.418344129861945,
    -6.218254645271765,
    -6.018416995673181,
    -5.818821884802928,
    -5.619460153502713,
    -5.420322773266531,
    -5.221400840015665,
    -5.022685568087653,
    -4.824168284426275,
    -4.625840422960327,
    -4.427693519159622,
    -4.229719204757259,
    -4.031909202627787,
    -3.834255321811377,
    -3.63674945267466,
    -3.439383562199266,
    -3.242149689389582,
    -3.045039940791565,
    -2.848046486114845,
    -2.651161553950656,
    -2.45437742757842,
    -2.257686440854105,
    -2.061080974173708,
    -1.864553450505445,
    -1.668096331484436,
    -1.471702113563864,
    -1.275363324216738,
    -1.079072518182558,
    -0.882822273753293,
    -0.686605189093212,
    -0.490413878587185,
    -0.294240969212185,
    -0.09807909692675,
    +0.09807909692675,
    +0.294240969212185,
    +0.490413878587185,
    +0.686605189093212,
    +0.882822273753293,
    +1.079072518182558,
    +1.275363324216738,
    +1.471702113563864,
    +1.668096331484436,
    +1.864553450505445,
    +2.061080974173708,
    +2.257686440854105,
    +2.45437742757842,
    +2.651161553950656,
    +2.848046486114845,
    +3.045039940791565,
    +3.242149689389582,
    +3.439383562199266,
    +3.63674945267466,
    +3.834255321811377,
    +4.031909202627787,
    +4.229719204757259,
    +4.427693519159622,
    +4.625840422960327,
    +4.824168284426275,
    +5.022685568087653,
    +5.221400840015665,
    +5.420322773266531,
    +5.619460153502713,
    +5.818821884802928,
    +6.018416995673181,
    +6.218254645271765,
    +6.418344129861945,
    +6.618694889506886,
    +6.819316515022294,
    +7.02021875520321,
    +7.221411524342481,
    +7.422904910059545,
    +7.624709181459459,
    +7.826834797643418,
    +8.029292416593492,
    +8.232092904455901,
    +8.435247345248879,
    +8.638767051023046,
    +8.842663572504284,
    +9.046948710251304,
    +9.251634526362552,
    +9.456733356769741,
    +9.662257824158183,
    +9.868220851557275,
    +10.07463567664794,
    +10.281515866837634,
    +10.488875335157653,
    +10.696728357042089,
    +10.905089588052742,
    +11.113974082619859,
    +11.323397313874614,
    +11.533375194655973,
    +11.743924099781964,
    +11.955060889683583,
    +12.166802935508609,
    +12.379168145812671,
    +12.59217499496605,
    +12.805842553417137,
    +13.020190519967282,
    +13.235239256227194,
    +13.451009823442301,
    +13.667524021893771,
    +13.884804433103549,
    +14.10287446509605,
    +14.321758400996547,
    +14.541481451277113,
    +14.762069809995873,
    +14.983550715414763,
    +15.205952515425822,
    +15.429304738266945,
    +15.653638169066099,
    +15.878984932819324,
    +16.105378584483803,
    +16.332854206954514,
    +16.561448517793379,
    +16.791199985695775,
    +17.022148957813458,
    +17.254337799208822,
    +17.48781104589695,
    +17.72261557314406,
    +17.958800780939722,
    +18.196418798852933,
    +18.435524712827875,
    +18.676176816885088,
    +18.918436893181771,
    +19.162370524468338,
    +19.408047443678916,
    +19.65554192623878,
    +19.904933231696582,
    +20.156306102538409,
    +20.409751329571602,
    +20.665366395153397,
    +20.923256207880053,
    +21.183533945274238,
    +21.4463220246819,
    +21.711753227242152,
    +21.979972005732403,
    +22.251136014735937,
    +22.525417911510261,
    +22.803007488961427,
    +23.084114219397521,
    +23.368970310885332,
    +23.657834409456067,
    +23.950996123628848,
    +24.248781608052615,
    +24.551560528643459,
    +24.859754855145845,
    +25.173850108958237,
    +25.494409967825709,
    +25.822095551225734,
    +26.157691379788251,
    +26.502141097849997,
    +26.856597908134813,
    +27.222497961270096,
    +27.601671075507828,
    +27.996515289006134,
    +28.410287565216026,
    +28.847623300317097,
    +29.315556495648897,
    +29.825809458081402,
    +30.401117779657764,
    +31.100951037096512
];

export const W: number[] = [
    2.9540145871800835e-211,
    5.020817756888436e-202,
    1.4509334500270392e-194,
    4.6913583190766487e-188,
    3.5359397081504361e-182,
    9.114457361834073e-177,
    1.0136208941909345e-171,
    5.675475271764095e-167,
    1.7847924896743182e-162,
    3.4184301509636211e-158,
    4.2436752264252759e-154,
    3.5864591066294724e-150,
    2.1469376259019049e-146,
    9.405157744755769e-143,
    3.0983962164454199e-139,
    7.855015208662244e-136,
    1.5630453427246388e-132,
    2.483285626512333e-129,
    3.1972924782982543e-126,
    3.3800825692122853e-123,
    2.968232250512191e-120,
    2.187664533015647e-117,
    1.365825513044208e-114,
    7.283840832590586e-112,
    3.3431060612647631e-109,
    1.3296527917278136e-106,
    4.6114419052210858e-104,
    1.4025800177880556e-101,
    3.7608844095106075e-99,
    8.933523233450524e-97,
    1.8882726818582927e-94,
    3.5662449072374122e-92,
    6.041278242751818e-90,
    9.212310177770136e-88,
    1.2687510116986887e-85,
    1.5830825023894391e-83,
    1.794794975103863e-81,
    1.8539433227865166e-79,
    1.749293204607109e-77,
    1.5113381294896974e-75,
    1.1983435235137525e-73,
    8.738839288315299e-72,
    5.872993924242821e-70,
    3.6444488891998228e-68,
    2.0919910037640666e-66,
    1.1127348357056033e-64,
    5.493356819255617e-63,
    2.5210019210640024e-61,
    1.0770598014915503e-59,
    4.2899178785744839e-58,
    1.5950823588115267e-56,
    5.543674120878083e-55,
    1.8031127826300552e-53,
    5.494967286136917e-52,
    1.5707547975689387e-50,
    4.2161469063242967e-49,
    1.0637272217126616e-47,
    2.5250874009530786e-46,
    5.64495985286486e-45,
    1.1895249269031469e-43,
    2.3647681386310267e-42,
    4.4387936139837483e-41,
    7.873130972441034e-40,
    1.320587994210242e-38,
    2.0962490291314412e-37,
    3.1512300703857922e-36,
    4.489246117399318e-35,
    6.064653597541806e-34,
    7.774105749852868e-33,
    9.461699941062774e-32,
    1.0939938866854915e-30,
    1.2023503699854981e-29,
    1.2567593992729679e-28,
    1.2499832905811925e-27,
    1.1836003268604515e-26,
    1.0674922835552106e-25,
    9.17456906985359e-25,
    7.517320905403345e-24,
    5.874694003747381e-23,
    4.3806011311093044e-22,
    3.1180535562838082e-21,
    2.1193549695588593e-20,
    1.3761221211258663e-19,
    8.538871215079432e-19,
    5.065072156588826e-18,
    2.8731516823222887e-17,
    1.5590499798725126e-16,
    8.095167500961375e-16,
    4.0233405909344399e-15,
    1.9145600624729613e-14,
    8.725571654881652e-14,
    3.8095919232928331e-13,
    1.5938054964152907e-12,
    6.391059556872471e-12,
    2.4569445682930554e-11,
    9.057389811973029e-11,
    3.202523456958324e-10,
    1.0863147662329805e-9,
    3.5357534962409985e-9,
    1.1044763292458928e-8,
    3.311773628664368e-8,
    9.533950007671909e-8,
    2.6355296294457982e-7,
    6.997086375244599e-7,
    1.7843872447157497e-6,
    4.3716822258132266e-6,
    1.0291000375452161e-5,
    2.3279572286335517e-5,
    5.0612366034536501e-5,
    1.0576827089812413e-4,
    2.1248156236438935e-4,
    4.1039424914329115e-4,
    7.6214868374154759e-4,
    1.3610623554229154e-3,
    2.3375177719222513e-3,
    3.8610584455446168e-3,
    6.1343092263553693e-3,
    9.3748300171492665e-3,
    1.3782486012401121e-2,
    1.9493216056892157e-2,
    2.652491316334555e-2,
    3.472635239182877e-2,
    4.3743820721247411e-2,
    5.3020239572973708e-2,
    6.1836746879648318e-2,
    6.9397141749149404e-2,
    7.4943512919414537e-2,
    7.7880553112849072e-2,
    7.7880553112849072e-2,
    7.4943512919414537e-2,
    6.9397141749149404e-2,
    6.1836746879648318e-2,
    5.3020239572973708e-2,
    4.3743820721247411e-2,
    3.472635239182877e-2,
    2.652491316334555e-2,
    1.9493216056892157e-2,
    1.3782486012401121e-2,
    9.3748300171492665e-3,
    6.1343092263553693e-3,
    3.8610584455446168e-3,
    2.3375177719222513e-3,
    1.3610623554229154e-3,
    7.6214868374154759e-4,
    4.1039424914329115e-4,
    2.1248156236438935e-4,
    1.0576827089812413e-4,
    5.0612366034536501e-5,
    2.3279572286335517e-5,
    1.0291000375452161e-5,
    4.3716822258132266e-6,
    1.7843872447157497e-6,
    6.997086375244599e-7,
    2.6355296294457982e-7,
    9.533950007671909e-8,
    3.311773628664368e-8,
    1.1044763292458928e-8,
    3.5357534962409985e-9,
    1.0863147662329805e-9,
    3.202523456958324e-10,
    9.057389811973029e-11,
    2.4569445682930554e-11,
    6.391059556872471e-12,
    1.5938054964152907e-12,
    3.8095919232928331e-13,
    8.725571654881652e-14,
    1.9145600624729613e-14,
    4.0233405909344399e-15,
    8.095167500961375e-16,
    1.5590499798725126e-16,
    2.8731516823222887e-17,
    5.065072156588826e-18,
    8.538871215079432e-19,
    1.3761221211258663e-19,
    2.1193549695588593e-20,
    3.1180535562838082e-21,
    4.3806011311093044e-22,
    5.874694003747381e-23,
    7.517320905403345e-24,
    9.17456906985359e-25,
    1.0674922835552106e-25,
    1.1836003268604515e-26,
    1.2499832905811925e-27,
    1.2567593992729679e-28,
    1.2023503699854981e-29,
    1.0939938866854915e-30,
    9.461699941062774e-32,
    7.774105749852868e-33,
    6.064653597541806e-34,
    4.489246117399318e-35,
    3.1512300703857922e-36,
    2.0962490291314412e-37,
    1.320587994210242e-38,
    7.873130972441034e-40,
    4.4387936139837483e-41,
    2.3647681386310267e-42,
    1.1895249269031469e-43,
    5.64495985286486e-45,
    2.5250874009530786e-46,
    1.0637272217126616e-47,
    4.2161469063242967e-49,
    1.5707547975689387e-50,
    5.494967286136917e-52,
    1.8031127826300552e-53,
    5.543674120878083e-55,
    1.5950823588115267e-56,
    4.2899178785744839e-58,
    1.0770598014915503e-59,
    2.5210019210640024e-61,
    5.493356819255617e-63,
    1.1127348357056033e-64,
    2.0919910037640666e-66,
    3.6444488891998228e-68,
    5.872993924242821e-70,
    8.738839288315299e-72,
    1.1983435235137525e-73,
    1.5113381294896974e-75,
    1.749293204607109e-77,
    1.8539433227865166e-79,
    1.794794975103863e-81,
    1.5830825023894391e-83,
    1.2687510116986887e-85,
    9.212310177770136e-88,
    6.041278242751818e-90,
    3.5662449072374122e-92,
    1.8882726818582927e-94,
    8.933523233450524e-97,
    3.7608844095106075e-99,
    1.4025800177880556e-101,
    4.6114419052210858e-104,
    1.3296527917278136e-106,
    3.3431060612647631e-109,
    7.283840832590586e-112,
    1.365825513044208e-114,
    2.187664533015647e-117,
    2.968232250512191e-120,
    3.3800825692122853e-123,
    3.1972924782982543e-126,
    2.483285626512333e-129,
    1.5630453427246388e-132,
    7.855015208662244e-136,
    3.0983962164454199e-139,
    9.405157744755769e-143,
    2.1469376259019049e-146,
    3.5864591066294724e-150,
    4.2436752264252759e-154,
    3.4184301509636211e-158,
    1.7847924896743182e-162,
    5.675475271764095e-167,
    1.0136208941909345e-171,
    9.114457361834073e-177,
    3.5359397081504361e-182,
    4.6913583190766487e-188,
    1.4509334500270392e-194,
    5.020817756888436e-202,
    2.9540145871800835e-211
];
