// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path d="M16.682 5.031c-2.673-0.167-5.316 0.647-7.431 2.29s-3.558 4.002-4.058 6.634c-0.499 2.632-0.021 5.355 1.346 7.659 0.14 0.237 0.177 0.52 0.101 0.785l-1.184 4.145 4.145-1.184c0.264-0.076 0.548-0.039 0.785 0.102 2.304 1.367 5.027 1.845 7.659 1.346s4.991-1.942 6.634-4.058c1.643-2.116 2.458-4.758 2.29-7.431s-1.305-5.194-3.199-7.088c-1.894-1.894-4.414-3.032-7.088-3.199zM8.023 5.742c2.5-1.942 5.623-2.905 8.783-2.707s6.139 1.542 8.377 3.781c2.239 2.239 3.583 5.217 3.781 8.377s-0.765 6.283-2.707 8.783c-1.942 2.5-4.73 4.206-7.84 4.796-2.967 0.563-6.032 0.074-8.672-1.374l-3.74 1.069c0 0-0 0-0 0-0.343 0.098-0.706 0.102-1.051 0.013s-0.66-0.27-0.913-0.522c-0.252-0.252-0.432-0.567-0.522-0.913s-0.085-0.708 0.013-1.051c0 0 0 0 0 0l1.068-3.74c-1.448-2.64-1.937-5.705-1.374-8.672 0.59-3.11 2.295-5.898 4.796-7.841z"></path>
  </svg>
)
