// React
import React, { FunctionComponent, useRef, useState } from 'react'

// Types
import { InputProps } from './input.types'

// Styles
import { Container, FeedbackWrapper, InputElement } from './input.styles'

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import { Icon } from 'component-library/icon'

export const Input: FunctionComponent<InputProps> = ({
  validation,
  label,
  labelFor,
  errorMsg,
  successMsg,
  handleStatus,
  ...inputDefaultProps
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [status, setStatus] = useState<string | null>(null)
  const [feedback, setFeedback] = useState<string | null>(null)
  const [pattern, setPattern] = useState<string | null>(null)

  const onBlur = () => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  const onFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const inputValidation = (value: any) => {
    if (value != null) {
      let newStatus = ''

      if (validation === 'name') {
        const hasNoSpecialChar = /^[a-záàâãéèêíïóôõöúçñ ]+$/i.test(value)

        if (!hasNoSpecialChar) {
          newStatus = 'negative'
          setFeedback('Caracteres inválidos')
        } else if (value.length < 3) {
          newStatus = 'negative'

          setFeedback(errorMsg!)
        } else {
          newStatus = 'positive'
          setFeedback(successMsg!)
        }
      } else if (validation === 'cel') {
        if (value.length === 11) {
          newStatus = 'positive'
          setFeedback(successMsg!)
        } else {
          newStatus = 'negative'
          setFeedback(errorMsg!)
        }
      } else if (validation === 'email') {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          newStatus = 'positive'
          setFeedback(successMsg!)
        } else {
          newStatus = 'negative'
          setFeedback(errorMsg!)

          setPattern(
            '^[\\w]{1,}[\\w.+-]{0,}@\\w{2,}([.][a-zA-Z]{2,}|[.][\\w-]{2,}[.][a-zA-Z]{2,})$'
          )
        }
      } else if (validation === 'cpf') {
        setPattern('(d{3}.?d{3}.?d{3}-?d{2})|(d{2}.?d{3}.?d{3}/?d{4}-?d{2})')

        if (value.length < 9) {
          newStatus = 'negative'
          setFeedback(errorMsg!)
        } else {
          newStatus = 'positive'
          setFeedback(successMsg!)
        }
      }

      if (value.length <= 0) {
        setStatus(null)
        setFeedback(null)
      }

      if (!!handleStatus) {
        setStatus(newStatus)
        handleStatus(newStatus as string)
      }
    }
  }

  return (
    <Container className="gabarito-modal-input">
      <ConditionallyRender
        shouldRender={!!label}
        content={<label htmlFor={labelFor}>{label}</label>}
      />

      <InputElement
        {...inputDefaultProps}
        pattern={pattern!}
        ref={inputRef}
        status={status!}
        type={inputDefaultProps.type as string}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={(event) => inputValidation(event.target.value)}
      />

      <ConditionallyRender
        shouldRender={!!feedback}
        content={
          <FeedbackWrapper status={status!}>
            <Icon
              name={status === 'negative' ? 'warningCircle' : 'doubleCheck'}
              color={status === 'negative' ? 'red' : 'green'}
            />

            {feedback}
          </FeedbackWrapper>
        }
      />
    </Container>
  )
}
