import React, { FunctionComponent } from 'react'
import { Container } from './toggle-switch.styles'

export const ToggleSwitch: FunctionComponent<any> = ({
  activeColor,
  isActive,
  handleClick
}) => {
  return (
    <Container isActive={isActive}>
      <input
        type="checkbox"
        id="toggle"
        className="toggle-checkbox"
        onClick={handleClick}
      />
      <label
        htmlFor="toggle"
        className="toggle-label"
        style={{
          backgroundColor: isActive ? activeColor : '#ced0dd'
        }}
      >
        <span className="toggle-label-background"></span>
      </label>
    </Container>
  )
}
